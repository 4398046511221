import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import uploadprifileimg from "../assets/images/upload-prifile-img.svg";
import SideMenu from "../components/SideMenu";

import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import { Link, NavLink } from "react-router-dom";

import avatar from "../assets/images/edit/avatar.png";
import { useForm } from "react-hook-form";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
} from "../constants/app-constants";

const options = [
  {
    name: "Enable backdrop (default)",
    scroll: false,
    backdrop: true,
  },
];

const EditProfile = () => {
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");

  const onSubmit = (data) => {};

  useEffect(() => {
    reset({
      full_name: "John Smith",
      email: "johnsmith@gmail.com",
      phone: "001138387731233",
      bio: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text.",
    });
  }, []);
  return (
    <>
      <Layout>
        <Breadcrumbs pagename="Edit Profile" />
        <div className="mainpadding">
          <section class="editprofile margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  xl={3}
                  xxl={2}
                  md={3}
                  sm={12}
                  className="mb-xl-auto sidebar-class-col"
                >
                  <SideMenu />
                </Col>
                {/* {options.map((props, idx) => (
                  <SideMenu key={idx} {...props} />
                ))} */}
                <Col sm={12} md={6} xl={5} xxl={4}>
                  <section className="edit-profile-form">
                    <h6>Edit Profile</h6>
                    <div className="border-bottomm"></div>

                    <div className="profile-form">
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        autocomplete="off"
                      >
                        <div className="img-div">
                          <label htmlFor="img-upload" className="file-upload">
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              id="img-upload"
                              name="avatar"
                              className="d-none"
                              onChange={(e) =>
                                setProfilePic({
                                  file: e.target.files[0],
                                  file_url: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                })
                              }
                            />
                            <img
                              src={
                                profilePic.file_url
                                  ? profilePic.file_url
                                  : uploadprifileimg
                              }
                              alt=""
                              className="w-100 h-100 img-upload"
                            />
                          </label>
                        </div>
                        <div className="input-fields">
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Full Name"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Full Name"
                              onKeyDown={(e) =>
                                EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                                e.preventDefault()
                              }
                              maxLength={VALIDATIONS.NAME}
                              {...register("full_name", {
                                maxLength: {
                                  value: VALIDATIONS.NAME,
                                  message: VALIDATIONS_TEXT.NAME_MAX,
                                },
                                pattern: {
                                  value: /^[a-zA-Z\s]*$/,
                                  message:
                                    VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.NAME_REQUIRED,
                                },
                              })}
                            />
                            {errors.full_name && (
                              <small className="text-red">
                                {errors.full_name.message}
                              </small>
                            )}
                          </FloatingLabel>

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email Address"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Email Address"
                              maxLength={VALIDATIONS.EMAIL}
                              {...register("email", {
                                maxLength: {
                                  value: VALIDATIONS.EMAIL,
                                  message: VALIDATIONS_TEXT.EMAIL_MAX,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                },
                                pattern: {
                                  value:
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                  message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                                },
                              })}
                            />
                            {errors.email && (
                              <small className="text-red">
                                {errors.email.message}
                              </small>
                            )}
                          </FloatingLabel>

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Contact Number"
                          >
                            <Form.Control
                              type="tel"
                              placeholder="Contact Number"
                              onKeyDown={(e) => {
                                EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                                  e.key.toUpperCase()
                                ) && e.preventDefault();
                              }}
                              maxLength={VALIDATIONS.PHONE_MAX}
                              {...register("phone", {
                                pattern: {
                                  value: /^(\d{16}|\+?\d{11}$|\d{10,12})/,
                                  message:
                                    VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                                },
                                minLength: {
                                  value: VALIDATIONS.PHONE_MIN,
                                  message: VALIDATIONS_TEXT.PHONE_MIN,
                                },
                                maxLength: {
                                  value: VALIDATIONS.PHONE_MAX,
                                  message: VALIDATIONS_TEXT.PHONE_MAX,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                                },
                              })}
                            />
                            {errors.phone && (
                              <small className="text-red">
                                {errors.phone.message}
                              </small>
                            )}
                          </FloatingLabel>

                          <label className="bio-label">Bio</label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            {...register("bio", {
                              maxLength: {
                                value: VALIDATIONS.BIO_MAX,
                                message: VALIDATIONS_TEXT.BIO_MAX,
                              },
                              required: {
                                value: false,
                                // message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                              },
                            })}
                          />
                        </div>
                        <Button className="w-100" type="submit">
                          Update Changes
                        </Button>
                      </Form>
                    </div>
                  </section>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default EditProfile;
