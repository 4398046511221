import React from 'react'
import { Link } from "react-router-dom";
import Cross from "../../assets/images/icons/cross.svg";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import {
  ProfileIcon,
  CardIcon,
  WalletIcon,
  OrderIcon,
  AdIcon,
  ReviewsIcon,
  SettingIcon,
  LogoutIcon,
  PointerArrowIcon,
} from "../../constants/svg";
import profileavatar from "../../assets/images/myprofilevatar.png";
const ProfilePanel = ({isProfileActive, setProfileIsActive}) => {
  
  const handleCloseProfile = (event) => {

    setProfileIsActive(false);
    document.body.classList.remove("active");
  };
  
  return (
    <>
        <section className="myprofile-popup">
        <div className="myprofile-heading-section">
          <h1>My Profile</h1>
          <div className="cross-icon">
            <div onClick={handleCloseProfile}>
            <Link>
              <img src={Cross} alt="cross" className="img-fluid" />
            </Link>
            </div>

          </div>
        </div>
        <hr className="bottom-border" />

        <div className="profilesection">
          <img src={profileavatar} alt="avatar" className="img-fluid" />
          <div className="infodiv">
            <h1>John Smith</h1>
            <p className="email">johnsmith@gmail.com</p>
            <p className="email">+12358966292</p>
            <p className="email">Texas</p>
          </div>
        </div>
        <p className="bio">
          This is dummy copy. It is not meant to be read. It has been placed
          here solely to demonstrate the look and feel of finished, typeset
          text.
        </p>
        <hr className="bottom-border" />

        <div className="myprofile-nav">
          <section className="myprofile-side-menu">
            <Nav defaultActiveKey="/editprofile" className="flex-column">
              <div className="inputfield">
                <ProfileIcon />
                <NavLink to="/editprofilepage"> Edit Profile</NavLink>
              </div>
              <div className="inputfield">
                <CardIcon />
                <NavLink to="/BrandCardPage"> Brand Cards</NavLink>
              </div>
              <div className="inputfield">
                <WalletIcon />
                <NavLink to="/WalletPage"> Wallet</NavLink>
              </div>
              <div className="inputfield">
                <OrderIcon />
                <NavLink to="/MyOrders"> My Orders</NavLink>
              </div>
              <div className="inputfield">
                <AdIcon />
                <NavLink to="/AdvertisementPage">
                  Advertisements
                </NavLink>
                <span>2</span>
              </div>
              <div className="inputfield">
                <ReviewsIcon />
                <NavLink to="/OrderReviewPage"> Reviews</NavLink>
              </div>
              <div className="inputfield">
                <SettingIcon />
                <NavLink to="/settings/ChangePasswordPage"> Settings</NavLink>
              </div>
              <div className="inputfield">
                <LogoutIcon />
                <NavLink to="/signin"> Log Out</NavLink>
              </div>
            </Nav>
          </section>
        </div>
      </section>
    </>
  )
}

export default ProfilePanel