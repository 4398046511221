import React from "react";
import Layout from "../../layouts/Layout";
import Breadcrumbs from "../breadcrumbs";

function AboutUs(props) {
  return (
    <Layout>
      <Breadcrumbs pagename="About" />

      <div className="mainpadding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="pagewrapper pt-50 pb-50 margin-bottom-150">
                <div className="mainpadding">
                  <div className="row">
                    <div className="col-md-10 col-sm-12">
                      <div className="aboutus-container">
                        <h3
                          className="subheading wow   fadeInDown"
                          data-wow-delay="0.12s"
                        >
                          About Us
                        </h3>

                        <p>
                          This is dummy copy. It is not meant to be read. It has
                          been placed here solely to demonstrate the look and
                          feel of finished, typeset text. Only for show. He who
                          searches for meaning here will be sorely disappointed.
                          These words are here to provide the reader with a
                          basic impression of how actual text will appear in its
                          final presentation.
                        </p>

                        <p>
                          This is dummy copy. It is not meant to be read. It has
                          been placed here solely to demonstrate the look and
                          feel of finished, typeset text. Only for show. He who
                          searches for meaning here will be sorely disappointed.
                          These words are here to provide the reader with a
                          basic impression of how actual text will appear in its
                          final presentation. This is dummy copy. It is not
                          meant to be read. It has been placed here solely to
                          demonstrate the look and feel of finished, typeset
                          text. Only for show. He who searches for meaning here
                          will be sorely disappointed. These words are here to
                          provide the reader with a basic impression of how
                          actual text will appear in its final presentation.
                        </p>

                        <p>
                          This is dummy copy. It is not meant to be read. It has
                          been placed here solely to demonstrate the look and
                          feel of finished, typeset text. Only for show. He who
                          searches for meaning here will be sorely disappointed.
                          These words are here to provide the reader with a
                          basic impression of how actual text will appear in its
                          final presentation. This is dummy copy. It is not
                          meant to be read. It has been placed here solely to
                          demonstrate the look and feel of finished, typeset
                          text.
                        </p>

                        <p>
                          This is dummy copy. It is not meant to be read. It has
                          been placed here solely to demonstrate the look and
                          feel of finished, typeset text. Only for show. He who
                          searches for meaning here will be sorely disappointed.
                          These words are here to provide the reader with a
                          basic impression of how actual text will appear in its
                          final presentation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
