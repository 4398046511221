import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import { Link, NavLink } from "react-router-dom";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  EXCLUDE_NUMBER_INPUT,
} from "../constants/app-constants";
const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("password");
  const onSubmit = (data) => {};
  return (
    <>
      <Layout>
        <Breadcrumbs pagename="Contact Us" />
        <div className="mainpadding">
          <section className="contact-us pagewrapper pt-50 pb-50 mainpadding margin-bottom-150">
            <Container fluid>
              <Row>
                <Col  sm={12} md={12} xxl={5} xl={6} lg={6}>
                  <div className="contact-information">
                    <h2>Contact Information</h2>
                    <p>
                      Fill up the form and our team will get back to you within
                      24 hours.
                    </p>
                    <div className="border-line"></div>
                    <div className="contact-details">
                      <ul>
                        <li>
                          <i class="fa-solid fa-phone"></i> +15484875152
                        </li>
                        <li>
                          <i class="fa-solid fa-envelope"></i>
                          Hello@zombiegiftcard.com
                        </li>
                        <li>
                          <i class="fa-solid fa-location-dot"></i> 102 street
                          27129 Down Town Hill Station, Texas.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col
                  xxl={{ span: 6, offset: 0 }}
                  xl={{ span: 5, offset: 1 }}
                  lg={{ span: 5, offset: 1 }}
                  sm={12}
                  md={12}
                  // lg={6}
                >
                  {/* <div className="col-sm-12 col-md-12 col-lg-7 offset-1"> */}
                  <div className="contact-form contact-us-padding">
                    <h2>Contact Us</h2>
                    <p>Any question or remarks? Just write us a message!</p>
                    <Form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
                      <Row className="mb-3">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="First Name"
                          as={Col}
                        >
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            onKeyDown={(e) =>
                              EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                              e.preventDefault()
                            }
                            maxLength={VALIDATIONS.NAME}
                            {...register("first_name", {
                              maxLength: {
                                value: VALIDATIONS.NAME,
                                message: VALIDATIONS_TEXT.NAME_MAX,
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]*$/,
                                message:
                                  VALIDATIONS_TEXT.INVALID_FORMAT_FIRST_NAME,
                              },
                              required: {
                                value: true,
                                message: VALIDATIONS_TEXT.FIRST_NAME_REQUIRED,
                              },
                            })}
                          />
                          {errors.first_name && (
                            <small className="text-red">
                              {errors.first_name.message}
                            </small>
                          )}
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Last Name"
                          as={Col}
                        >
                          <Form.Control
                            onKeyDown={(e) =>
                              EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                              e.preventDefault()
                            }
                            type="text"
                            placeholder="Last Name"
                            maxLength={VALIDATIONS.NAME}
                            {...register("last_name", {
                              maxLength: {
                                value: VALIDATIONS.NAME,
                                message: VALIDATIONS_TEXT.NAME_MAX,
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]*$/,
                                message:
                                  VALIDATIONS_TEXT.INVALID_FORMAT_LAST_NAME,
                              },
                              required: {
                                value: true,
                                message: VALIDATIONS_TEXT.LAST_NAME_REQUIRED,
                              },
                            })}
                          />
                          {errors.last_name && (
                            <small className="text-red">
                              {errors.last_name.message}
                            </small>
                          )}
                        </FloatingLabel>
                      </Row>
                      <Row className="mb-3">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Email Address"
                          as={Col}
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            maxLength={VALIDATIONS.EMAIL}
                            {...register("email", {
                              maxLength: {
                                value: VALIDATIONS.EMAIL,
                                message: VALIDATIONS_TEXT.EMAIL_MAX,
                              },
                              required: {
                                value: true,
                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                              },
                              pattern: {
                                value:
                                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                              },
                            })}
                          />
                          {errors.email && (
                            <small className="text-red">
                              {errors.email.message}
                            </small>
                          )}
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Phone (Optional)"
                          as={Col}
                        >
                          <Form.Control
                            type="tel"
                            placeholder="Phone (Optional)"
                            onKeyDown={(e) => {
                              EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                                e.key.toUpperCase()
                              ) && e.preventDefault();
                            }}
                            maxLength={VALIDATIONS.PHONE_MAX}
                            {...register("phone", {
                              pattern: {
                                value: /^(\d{16}|\+?\d{11}$|\d{10,12})/,
                                message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                              },
                              minLength: {
                                value: VALIDATIONS.PHONE_MIN,
                                message: VALIDATIONS_TEXT.PHONE_MIN,
                              },
                              maxLength: {
                                value: VALIDATIONS.PHONE_MAX,
                                message: VALIDATIONS_TEXT.PHONE_MAX,
                              },
                              required: {
                                value: false,
                                message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                              },
                            })}
                          />
                          {errors.phone && (
                            <small className="text-red">
                              {errors.phone.message}
                            </small>
                          )}
                        </FloatingLabel>
                      </Row>
                      <div class="text-area">
                        {/* <FloatingLabel
                          controlId="floatingInput"
                          label="Message"
                          className="mb-3"
                        >                        </FloatingLabel> */}
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={1} />
                      </div>
                      <Row>
                        <Col xl={3} className="w-100">
                          <Button
                            className="button  submitbtn btn-solid-blue"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};
export default Contact;
