import mainlogo from '../assets/images/main-logo.png';
import searchicon from '../assets/images/search-icon.png';
import notificationicon from '../assets/images/notification-icon.png';
import carticon from '../assets/images/cart-icon.png';
import profilepic from '../assets/images/profilepic.png';
import filtericon from '../assets/images/filter-icon.png';
import newarrival from '../assets/images/new-arrival.png';
import popular from '../assets/images/popular.png';
import advertisement from '../assets/images/advertisement.png';
import appstore from '../assets/images/appstore.png';
import googleplay from '../assets/images/googleplay.png';
import footerlogo from '../assets/images/footer-logo.png';
import productcardimg from '../assets/images/advertisement/ad3.png';
// import nikebrand from '../assets/images/brands/nike.png';
// import aboutimg from '../assets/images/about/aboutimg.png';
// import product1 from '../assets/images/products/product-1.png';
import arrowicon from '../assets/images/icons/arrow-icon.svg';
export default{
    mainlogo,
    searchicon,
    notificationicon,
    carticon,
    profilepic,
    filtericon,
    newarrival,
    popular,
    advertisement,
    appstore,
    googleplay,
    footerlogo,
    // nikebrand,
    // aboutimg,
    arrowicon,
    // product1,
    productcardimg,
}