import React from 'react';
import { Link } from 'react-router-dom';
import splashLogo from '../../assets/images/splashLogo.svg';



function Splashscreen(props) {
    return (

        <section className="splashscreen ">
            <section className="container h-100">
                <div className="row h-100">
                    <div className="col-md-12 my-auto">
                        <div className="middlearea   ">
                            <div className="splashLogo">

                                <img src={splashLogo} alt="" className=' wow bounceInUp ' />
                                <h2 className='wow bounceInUp ' data-wow-delay="0.9s" >Welcome to
                                   <span>Gift Kard Zombie</span> </h2>
                                <p className='wow  fadeInDown '   data-wow-delay="1.5s" >Go and fetch the details of your gift cards</p>
                                <Link to="/SelectYourRole" className='wow bounceInUp '  data-wow-delay="1.12s">Get Started</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </section>




    );
}

export default Splashscreen;