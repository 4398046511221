import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";

import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../constants/app-constants";
function RecoverPassword(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/PasswordVerification")
  };
  return (
    <div className="authbbox">
      <h2>Recover Password</h2>
      <p className="pad-lf50">
        Enter the email associated with your account and we'll send verification
        code to reset your password
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FloatingLabel
          label="Email address"
          className="inputfeildarea text-start"
        >
          <Form.Control
            type="email"
            placeholder="name@example.com"
            maxLength={VALIDATIONS.EMAIL}
            {...register("email", {
              maxLength: {
                value: VALIDATIONS.EMAIL,
                message: VALIDATIONS_TEXT.EMAIL_MAX,
              },
              required: {
                value: true,
                message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
              },
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: VALIDATIONS_TEXT.EMAIL_FORMAT,
              },
            })}
          />
          {errors.email && (
            <small className="text-red">{errors.email.message}</small>
          )}
        </FloatingLabel>

        <Button
          type="submit"
          className="mg-tb-40 button d-block btn-solid-blue w-100"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

export default RecoverPassword;
