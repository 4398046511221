import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import uploadprifileimg from "../../assets/images/upload-prifile-img.svg";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import EyeClose from "../../assets/images/svg/close-eye.svg";
import OpenEye from "../../assets/images/svg/open-eye.svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  EXCLUDE_NUMBER_INPUT,
  VALIDATIONS_TEXT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
} from "../../constants/app-constants";

const SignUp = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/VerificationForm");
  };

  return (
    <div className="authbbox">
      <h2>Create an Account</h2>
      <div className="form-width">
        <p className="para f-18">Please fill the following details</p>
        <label htmlFor="img-upload" className="file-upload">
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            id="img-upload"
            name="avatar"
            className="d-none"
            onChange={(e) =>
              setProfilePic({
                file: e.target.files[0],
                file_url: URL.createObjectURL(e.target.files[0]),
              })
            }
          />

          <img
            src={profilePic.file_url ? profilePic.file_url : uploadprifileimg}
            alt=""
            className="w-100 h-100 img-upload"
          />
        </label>
        <p className="para">Add Profile Picture</p>

        <div className="form-feild text-start">
          <Form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
            <FloatingLabel label="Full Name" className="inputfeildarea">
              <Form.Control
                onKeyDown={(e) =>
                  EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                }
                type="text"
                placeholder="Full Name"
                maxLength={VALIDATIONS.NAME}
                {...register("full_name", {
                  maxLength: {
                    value: VALIDATIONS.NAME,
                    message: VALIDATIONS_TEXT.NAME_MAX,
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]*$/,
                    message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                  },
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.NAME_REQUIRED,
                  },
                })}
              />
              {errors.full_name && (
                <small className="text-red">{errors.full_name.message}</small>
              )}
            </FloatingLabel>

            <FloatingLabel label="Email address" className="inputfeildarea">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                maxLength={VALIDATIONS.EMAIL}
                {...register("email", {
                  maxLength: {
                    value: VALIDATIONS.EMAIL,
                    message: VALIDATIONS_TEXT.EMAIL_MAX,
                  },
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                  },
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                  },
                })}
              />
              {errors.email && (
                <small className="text-red">{errors.email.message}</small>
              )}
            </FloatingLabel>

            <FloatingLabel label="Password" className="inputfeildarea">
              <Form.Control
                placeholder="Password"
                type={!showCurrentPassword ? "password" : "text"}
                maxLength={VALIDATIONS.PASS}
                {...register("password", {
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                    message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                  },
                })}
              />
              <span className="iconfeild">
                {showCurrentPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowCurrentPassword(false)}
                  >
                    <img src={OpenEye} alt="" title="" />
                  </span>
                ) : (
                  <span className="toggle-password">
                    <img
                      src={EyeClose}
                      alt=""
                      title=""
                      onClick={() => setShowCurrentPassword(true)}
                    />
                  </span>
                )}
              </span>
            </FloatingLabel>

            {errors.password && (
              <small className="text-red">{errors.password.message}</small>
            )}

            <FloatingLabel
              label="Confirm Password  *"
              className="inputfeildarea"
            >
              <Form.Control
                type={!showConfirmPassword ? "password" : "text"}
                placeholder="Confirm Password  *"
                maxLength={VALIDATIONS.PASS}
                {...register("password_confirmation", {
                  minLength: {
                    value: VALIDATIONS.PASSWORD_MIN,
                    message: VALIDATIONS_TEXT.PASSWORD_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PASSWORD_MAX,
                    message: VALIDATIONS_TEXT.PASSWORD_MAX,
                  },
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                  },
                  validate: (value) =>
                    value === password ||
                    "Password and Confirm Password must be same",
                })}
              />
              <span className="iconfeild">
                {showConfirmPassword ? (
                  <span
                    className="toggle-password"
                    onClick={() => setShowConfirmPassword(false)}
                  >
                    <img src={OpenEye} alt="" title="" />
                  </span>
                ) : (
                  <span className="toggle-password">
                    <img
                      src={EyeClose}
                      alt=""
                      title=""
                      onClick={() => setShowConfirmPassword(true)}
                    />
                  </span>
                )}
              </span>
            </FloatingLabel>
            {errors.password_confirmation && (
              <small className="text-red">
                {errors.password_confirmation.message}
              </small>
            )}
            <FloatingLabel label="Contact Number *" className="inputfeildarea">
              <Form.Control
                type="tel"
                placeholder="Contact Number *"
                onKeyDown={(e) => {
                  EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                    e.key.toUpperCase()
                  ) && e.preventDefault();
                }}
                maxLength={VALIDATIONS.PHONE_MAX}
                {...register("phone", {
                  pattern: {
                    value: /^(\d{16}|\+?\d{11}$|\d{10,12})/,
                    message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                  },
                  minLength: {
                    value: VALIDATIONS.PHONE_MIN,
                    message: VALIDATIONS_TEXT.PHONE_MIN,
                  },
                  maxLength: {
                    value: VALIDATIONS.PHONE_MAX,
                    message: VALIDATIONS_TEXT.PHONE_MAX,
                  },
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                  },
                })}
              />
              {errors.phone && (
                <small className="text-red">{errors.phone.message}</small>
              )}
            </FloatingLabel>

            <FloatingLabel label="State *" className="inputfeildarea">
              <Form.Control
                onKeyDown={(e) =>
                  EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                }
                type="text"
                placeholder="State *"
                maxLength={VALIDATIONS.STATE}
                {...register("state", {
                  maxLength: {
                    value: VALIDATIONS.NAME,
                    message: VALIDATIONS_TEXT.STATE,
                  },
                  pattern: {
                    value: /^[a-z0-9]+$/gi,
                    message: VALIDATIONS_TEXT.INVALID_STATE_FORMAT,
                  },
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.STATE_REQUIRED,
                  },
                })}
              />
              {errors.state && (
                <small className="text-red">{errors.state.message}</small>
              )}
            </FloatingLabel>

            <button
              type="submit"
              className="button w-100 mg-tb-40 btn-solid-blue"
              name="Sign Up"
            >
              Sign Up
            </button>
          </Form>
        </div>
      </div>
      <p class="text-center">
        Already have an account? <NavLink to="/signin" className="linkgreen">Sign In</NavLink>
      </p>
    </div>
  );
};

export default SignUp;
