import React, { useState } from "react";

import homelogoImage from "../assets/images/logo.svg";
import userProfileImage from "../assets/images/profile-person.png";
import { Link, useLocation, NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NotificationPanel from "../components/SideBar/NotificationPanel";
import ProfilePanel from "../components/SideBar/ProfilePanel";
import Cross from "../assets/images/icons/cross.svg";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import FilterPopup from "../components/Modals/FilterPopup";
import SearchPopup from "../components/Modals/SearchPopup";
import { NavNotificationIcon, NavSearchIcon } from "../constants/svg";
function Header({isShow}) {
  const [isActive, setIsActive] = useState(false);
  const [isProfileActive, setProfileIsActive] = useState(false);
  const [modalFilterShow, setFilterModalShow] = useState(false);
  const [modalSearchShow, setSearchModalShow] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const handleShow = (event) => {
    setIsActive((current) => !current);
    document.body.classList.add("active");
  };
  const handleClose = (event) => {
    setIsActive(false);
    document.body.classList.remove("active");
  };
  const handleShowProfile = (event) => {
    setProfileIsActive((current) => !current);
    document.body.classList.add("active");
  };
  const handleCloseProfile = (event) => {
    setProfileIsActive(false);
    document.body.classList.remove("active");
  };
  return (
    <section className="header">
      <section className="black">
        <div className="mainpadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="logos">
                  <NavLink to="/home">
                  <img src={homelogoImage} alt="" />
                  </NavLink>
                  
                </div>
              </div>
              <div className="col-md-6">
                <ul className="profilelist">
                  <li>
                    <NavLink onClick={() => setSearchModalShow(true)}>
                      <div className="layericonbg">
                        <span>
                          <NavSearchIcon/>
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={handleShow}>
                      <div className="layericonbg">
                        <span>
                          <b></b>
                          <NavNotificationIcon/>
                        </span>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/Shoppingcart">
                      <div className="layericonbg">
                        <span>
                          <svg
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.28418 1L3.24409 1.33922L4.1515 12.1498C4.22405 13.0327 4.96185 13.7102 5.84758 13.7074H16.1268C16.972 13.7093 17.689 13.0874 17.8087 12.2507L18.7029 6.07128C18.8028 5.3806 18.3232 4.73986 17.6335 4.63998C17.5731 4.6315 3.55881 4.62679 3.55881 4.62679"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.0024 8.10932H14.6153"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M5.43422 16.9739C5.71785 16.9739 5.94682 17.2038 5.94682 17.4865C5.94682 17.7701 5.71785 18 5.43422 18C5.1506 18 4.92163 17.7701 4.92163 17.4865C4.92163 17.2038 5.1506 16.9739 5.43422 16.9739Z"
                              fill="white"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.0634 16.9739C16.347 16.9739 16.5769 17.2038 16.5769 17.4865C16.5769 17.7701 16.347 18 16.0634 18C15.7798 18 15.5508 17.7701 15.5508 17.4865C15.5508 17.2038 15.7798 16.9739 16.0634 16.9739Z"
                              fill="white"
                              stroke="white"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink onClick={handleShowProfile}>
                      <div className="layericonbg">
                        <span>
                          <img src={userProfileImage} alt="" />
                        </span>
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={!isActive ? "notificationpanel" : "notificationpanel active"}
      >
        {/* <div onClick={handleClose}>
          <Link>
            <img src={Cross} alt="cross" className="img-fluid" />
          </Link>
        </div> */}
        <NotificationPanel isActive={isActive} setIsActive={setIsActive}/>
      </div>
      <div
        className={!isProfileActive ? "profilepanel" : "profilepanel active"}
      >
        <div onClick={handleCloseProfile}>
          {/* <Link>
            <img src={Cross} alt="cross" className="img-fluid cross-icon" />
          </Link> */}
        </div>
        <ProfilePanel isProfileActive={isProfileActive} setProfileIsActive={setProfileIsActive}/>
      </div>

      <div className="mainpadding">
        <div className="second-navbar-container">
          <Navbar expand="lg">
            <Container fluid>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <nav>
                  <ul>
                    <li>
                      <NavLink to="/home">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/brands">Brands</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact">Contact Us</NavLink>
                    </li>
                  </ul>
                </nav>
                <Form className="d-flex ms-auto">
                  {
                  isShow && (
                    <NavLink
                      className="home-filter-button d-none d-lg-block"
                      onClick={() => setFilterModalShow(true)}
                    >
                      <svg
                        width="23"
                        height="20"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.1431 4.12001C12.1431 3.70478 12.4789 3.36816 12.8931 3.36816H20.5885C21.0027 3.36816 21.3385 3.70478 21.3385 4.12001C21.3385 4.53524 21.0027 4.87186 20.5885 4.87186H12.8931C12.4789 4.87186 12.1431 4.53524 12.1431 4.12001Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.979248 3.98537C0.979248 1.9787 2.6125 0.36084 4.61548 0.36084C6.61846 0.36084 8.25171 1.9787 8.25171 3.98537C8.25171 5.99204 6.61846 7.6099 4.61548 7.6099C2.6125 7.6099 0.979248 5.99204 0.979248 3.98537ZM4.61548 1.86454C3.43077 1.86454 2.47925 2.81932 2.47925 3.98537C2.47925 5.15142 3.43077 6.1062 4.61548 6.1062C5.80019 6.1062 6.75171 5.15142 6.75171 3.98537C6.75171 2.81932 5.80019 1.86454 4.61548 1.86454Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.01514 16.1496C1.01514 15.7343 1.35092 15.3977 1.76514 15.3977H9.46054C9.87475 15.3977 10.2105 15.7343 10.2105 16.1496C10.2105 16.5648 9.87475 16.9014 9.46054 16.9014H1.76514C1.35092 16.9014 1.01514 16.5648 1.01514 16.1496Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.7483 16.0149C14.7483 14.0081 16.3817 12.3904 18.3855 12.3904C20.3888 12.3904 22.0208 14.0086 22.0208 16.0149C22.0208 18.0213 20.3888 19.6395 18.3855 19.6395C16.3817 19.6395 14.7483 18.0218 14.7483 16.0149ZM18.3855 13.8941C17.1996 13.8941 16.2483 14.849 16.2483 16.0149C16.2483 17.1808 17.1996 18.1357 18.3855 18.1357C19.5699 18.1357 20.5208 17.1813 20.5208 16.0149C20.5208 14.8485 19.5699 13.8941 18.3855 13.8941Z"
                          fill="white"
                        />
                      </svg>
                      Filter
                    </NavLink>
                  )}
                </Form>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div className="filter-button d-block d-lg-none">
            <NavLink
              className="blue-button"
              onClick={() => setFilterModalShow(true)}
            >
              <svg
                width="23"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.1431 4.12001C12.1431 3.70478 12.4789 3.36816 12.8931 3.36816H20.5885C21.0027 3.36816 21.3385 3.70478 21.3385 4.12001C21.3385 4.53524 21.0027 4.87186 20.5885 4.87186H12.8931C12.4789 4.87186 12.1431 4.53524 12.1431 4.12001Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.979248 3.98537C0.979248 1.9787 2.6125 0.36084 4.61548 0.36084C6.61846 0.36084 8.25171 1.9787 8.25171 3.98537C8.25171 5.99204 6.61846 7.6099 4.61548 7.6099C2.6125 7.6099 0.979248 5.99204 0.979248 3.98537ZM4.61548 1.86454C3.43077 1.86454 2.47925 2.81932 2.47925 3.98537C2.47925 5.15142 3.43077 6.1062 4.61548 6.1062C5.80019 6.1062 6.75171 5.15142 6.75171 3.98537C6.75171 2.81932 5.80019 1.86454 4.61548 1.86454Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.01514 16.1496C1.01514 15.7343 1.35092 15.3977 1.76514 15.3977H9.46054C9.87475 15.3977 10.2105 15.7343 10.2105 16.1496C10.2105 16.5648 9.87475 16.9014 9.46054 16.9014H1.76514C1.35092 16.9014 1.01514 16.5648 1.01514 16.1496Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.7483 16.0149C14.7483 14.0081 16.3817 12.3904 18.3855 12.3904C20.3888 12.3904 22.0208 14.0086 22.0208 16.0149C22.0208 18.0213 20.3888 19.6395 18.3855 19.6395C16.3817 19.6395 14.7483 18.0218 14.7483 16.0149ZM18.3855 13.8941C17.1996 13.8941 16.2483 14.849 16.2483 16.0149C16.2483 17.1808 17.1996 18.1357 18.3855 18.1357C19.5699 18.1357 20.5208 17.1813 20.5208 16.0149C20.5208 14.8485 19.5699 13.8941 18.3855 13.8941Z"
                  fill="white"
                />
              </svg>
              Filter
            </NavLink>
            <FilterPopup
              show={modalFilterShow}
              setFiltered = {setFiltered}
              onHide={() => setFilterModalShow(false)}
            />
            <SearchPopup
              show={modalSearchShow}
              onHide={() => setSearchModalShow(false)}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
