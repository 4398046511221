import React from 'react';
import logoimageicon from "../../assets/images/loginlogo.png";
import {Link} from "react-router-dom";

function SelectYourRole(props) {
    return (

        <div className="authbbox">
            <h2>Select Your Role</h2>

            <img src={logoimageicon} alt="" />

            <p className="para">Select your role to get started</p>

            <Link  to="/Signup"  className="button d-block btn-solid-blue ">Customer</Link>

            <Link to="/brands" className="button d-block btn-solid-green">Brand</Link>

            <div className="already-div">
            <p>Already have an account? <Link  to="/signin" > Sign In</Link></p>
            </div>
           

        </div>



    );
}

export default SelectYourRole;