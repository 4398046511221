import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
function TrendingProducts(props) {
  let dataarrays = props.dataarrays;
  return (
    <section className="mainpadding">
      <section className="trendingproduct mt-100">
        <div className="container-fluid">
          <div className="row">
            <Col sm={12}>
              <div className="trending-nav-heading">
                <h2>{props.title}</h2>
                <div className="slider-btns">
                  <a className="view-button">View All</a>
                </div>
              </div>
            </Col>
          </div>

          <div className="row">
            {dataarrays.map((els, i) => (
              <div className="col-md-15 col-sm-4 col-6">
                <Card className="product-box" key={i}>
                  <div className="cardimg">
                    <Link to="/productdetailpage">
                      <Card.Img
                        variant="top"
                        src={els.src}
                        alt={els.alt}
                        className="img-fluid"
                      />
                    </Link>
                  </div>

                  <Card.Body>
                    <Card.Title>{els.title}</Card.Title>
                    <Card.Text>{els.price}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
}

export default TrendingProducts;
