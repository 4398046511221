import React from 'react' ;
import HomeBannerImg from '../../assets/images/banner-img.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function BannerSection(props) {
    return (
        <section className="mainpadding">
      <section className="banner-section">
        <Container fluid>
          <div className="banner-wrapper">
            <Row className="h-100">
                <Col md={12} lg={12} xl={6} className="my-auto">
                <div className="banner-content">
                  <h1>Welcome to <br/>Gift Kard Zombie</h1>
                  <p>
                    This is dummy copy. It is not meant to be read. It has been
                    placed here solely to demonstrate the look and feel of
                    finished, typeset text.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
        </section>

    );
}

export default BannerSection;