import React, { useState } from "react";
import { Col, Container, Row, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import SideMenu from "../components/SideMenu";
import SettingMenu from "../components/SettingMenu";

import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";

import EyeClose from "../assets/images/svg/close-eye.svg";
import OpenEye from "../assets/images/svg/open-eye.svg";

import Nav from "react-bootstrap/Nav";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../constants/app-constants";
const Setting = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("newPassword");

  const onSubmit = (data) => {};

  return (
    <>
      <Layout>
        <Breadcrumbs parentPage="Settings" pagename="Change Password" />
        <div className="mainpadding">
          <section className="main-changepassword-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  xl={3}
                  xxl={2}
                  md={3}
                  sm={12}
                  className="mb-xl-auto sidebar-class-col"
                >
                  <SideMenu />
                </Col>

                <Col className="setting-col" sm={12} md={4} xl={4} xxl={3}>
                  <SettingMenu />
                </Col>

                <Col
                  className="changepassword-col"
                  sm={12}
                  md={4}
                  xl={5}
                  xxl={4}
                >
                  <section className="change-password">
                    <h6>Change Password</h6>
                    <p>
                      Your new password must be different from previous used
                      passwords
                    </p>

                    <div className="form-section">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Floating className="mb-3">
                          <Form.Control
                            id="currentPassword"
                            placeholder="Current Password"
                            type={!showCurrentPassword ? "password" : "text"}
                            maxLength={VALIDATIONS.PASS}
                            {...register("current_password", {
                              minLength: {
                                value: VALIDATIONS.PASSWORD_MIN,
                                message: VALIDATIONS_TEXT.PASSWORD_MIN,
                              },
                              maxLength: {
                                value: VALIDATIONS.PASSWORD_MAX,
                                message: VALIDATIONS_TEXT.PASSWORD_MAX,
                              },
                              required: {
                                value: true,
                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                              },
                              pattern: {
                                value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                              },
                            })}
                          />
                          <label htmlFor="currentPassword">
                            Current Password
                          </label>
                          {showCurrentPassword ? (
                            <span
                              className="toggle-password"
                              onClick={() => setShowCurrentPassword(false)}
                            >
                              <img src={OpenEye} alt="" title="" />
                            </span>
                          ) : (
                            <span className="toggle-password">
                              <img
                                src={EyeClose}
                                alt=""
                                title=""
                                onClick={() => setShowCurrentPassword(true)}
                              />
                            </span>
                          )}
                          {errors.current_password&& (
                            <small className="text-red">
                              {errors.current_password.message}
                            </small>
                          )}
                        </Form.Floating>

                        <Form.Floating className="mb-3">
                          <Form.Control
                            id="NewPassword"
                            type={!showNewPassword ? "password" : "text"}
                            placeholder="New Password"
                            maxLength={VALIDATIONS.PASS}
                            {...register("newPassword", {
                              minLength: {
                                value: VALIDATIONS.PASSWORD_MIN,
                                message: VALIDATIONS_TEXT.PASSWORD_MIN,
                              },
                              maxLength: {
                                value: VALIDATIONS.PASSWORD_MAX,
                                message: VALIDATIONS_TEXT.PASSWORD_MAX,
                              },
                              required: {
                                value: true,
                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                              },
                              pattern: {
                                value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                                message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                              },
                            })}
                          />
                          <label htmlFor="NewPassword">New Password</label>
                          {showNewPassword ? (
                            <span
                              className="toggle-password"
                              onClick={() => setShowNewPassword(false)}
                            >
                              <img src={OpenEye} alt="" title="" />
                            </span>
                          ) : (
                            <span className="toggle-password">
                              <img
                                src={EyeClose}
                                alt=""
                                title=""
                                onClick={() => setShowNewPassword(true)}
                              />
                            </span>
                          )}
                          {errors.newPassword && (
                            <small className="text-red">
                              {errors.newPassword.message}
                            </small>
                          )}
                        </Form.Floating>
                        
                        <Form.Floating className="mb-3">
                          <Form.Control
                            id="ConfirmPassword"
                            type={!showConfirmPassword ? "password" : "text"}
                            placeholder="Confirm New Password"
                            maxLength={VALIDATIONS.PASS}
                            {...register("confirmPassword", {
                              minLength: {
                                value: VALIDATIONS.PASSWORD_MIN,
                                message: VALIDATIONS_TEXT.PASSWORD_MIN,
                              },
                              maxLength: {
                                value: VALIDATIONS.PASSWORD_MAX,
                                message: VALIDATIONS_TEXT.PASSWORD_MAX,
                              },
                              required: {
                                value: true,
                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                              },
                              
                              validate: (value) =>
                                value === password ||
                                "Password and Confirm Password must be same",
                            })}
                          />
                          <label htmlFor="ConfirmPassword">
                            Confirm New Password
                          </label>
                          {showConfirmPassword ? (
                            <span
                              className="toggle-password"
                              onClick={() => setShowConfirmPassword(false)}
                            >
                              <img src={OpenEye} alt="" title="" />
                            </span>
                          ) : (
                            <span className="toggle-password">
                              <img
                                src={EyeClose}
                                alt=""
                                title=""
                                onClick={() => setShowConfirmPassword(true)}
                              />
                            </span>
                          )}
                          {errors.confirmPassword && (
                            <small className="text-red">
                              {errors.confirmPassword.message}
                            </small>
                          )}
                        </Form.Floating>

                        <Button className="w-100" size="lg" type="submit">
                          Change Password
                        </Button>
                      </Form>
                    </div>
                  </section>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Setting;
