import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { Notification, Lock, PrivacyIcon, TermsIcon } from "../constants/svg";
const SettingMenu = () => {
  return (
    <>
      <div className="settings">
        <h6>Settings</h6>
        <div className="setting-tab">
          <Nav defaultActiveKey="/ChangePasswordPage" className="flex-column">
            <div className="inputfield">
              <NavLink to="#" className="noactive">
                <span>
                  <Notification />
                </span>
                Push Notification
              </NavLink>
              <input type="checkbox" hidden="hidden" id="username" />
              <label class="switch" for="username"></label>
            </div>
            <div className="inputfield">
              <NavLink to="/settings/ChangePasswordPage">
                <span>
                  
                  <Lock />
                </span>
                Change Password
              </NavLink>
            </div>
            <div className="inputfield">
              <NavLink to="/settings/termspage">
                <span>
                  <TermsIcon />
                </span>
                Terms & Conditions
              </NavLink>
            </div>
            <div className="inputfield">
              <NavLink to="/settings/privacypage">
                <span>
                  <PrivacyIcon />
                </span>
                Privacy Policy
              </NavLink>
            </div>
          </Nav>
        </div>
      </div>
    </>
  );
};

export default SettingMenu;
