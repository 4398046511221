import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import SideMenu from "../components/SideMenu";
import { ChatIcon } from "../constants/svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import OrderCompletedPopup from "../components/Modals/OrderCompletedPopup";
import orderimg1 from "../assets/images/myorders/orderimg1.png";

import StoreChat from "../components/StoreChat";

function StoreChatPopup({ modalChatShow, setModalChatShow }) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={modalChatShow}
    >
      <Modal.Body>
        <StoreChat setModalChatShow={setModalChatShow} />
      </Modal.Body>
    </Modal>
  );
}

const BrandCards = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalChatShow, setModalChatShow] = React.useState(false);
  const [hidden, setHidden] = useState(false);
  const closedModal = () => {
    setModalShow(false);
  };

  return (
    <>
      <Layout>
        <Breadcrumbs pagename="My Orders" />
        <div className="mainpadding">
          <section class="main-orders-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  xl={2}
                  xxl={2}
                  className="sidebar-class-col"
                >
                  <SideMenu />
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7}
                  xl={6}
                  xxl={6}
                  className="myorder-details-col"
                >
                  <div className="order-section">
                    <h6>My Orders</h6>
                    <div className="border-bottomm"></div>
                    <div className="tabs-section">
                      <ul
                        class="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active right-border"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            Current Orders
                          </button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            Past Orders
                          </button>
                        </li>
                      </ul>

                      {/* Tab Content Start */}
                      <div
                        class="tab-content myorder-tab-content"
                        id="pills-tabContent"
                      >
                        <div
                          class="tab-pane fade show active present-orders myorder-pills-home"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="order-card">
                            <div className="img-title-div">
                              <img
                                src={orderimg1}
                                alt="orderimg"
                                className="img-fluid"
                              />
                              <div className="title-date">
                                <p>16 March 2022 - 02:30 am</p>
                                <h2>Nike Air Max</h2>
                              </div>
                            </div>
                            <div className="order-quantity">
                              <p>x1</p>
                            </div>
                            <div className="order-price">
                              <p>$125.00</p>
                            </div>
                            <div className="status confirmed">
                              <p>Confirmed</p>
                              <Link
                                onClick={() => setHidden(true)}
                                className="d-none d-xl-block"
                              >
                                <span>
                                  <ChatIcon />
                                </span>
                              </Link>
                              <Link
                                onClick={() => setModalChatShow(true)}
                                className="d-block d-xl-none"
                              >
                                <span>
                                  <ChatIcon />
                                </span>
                              </Link>
                            </div>
                          </div>

                          <div className="order-card">
                            <div className="img-title-div">
                              <img
                                src={orderimg1}
                                alt="orderimg"
                                className="img-fluid"
                              />
                              <div className="title-date">
                                <p>16 March 2022 - 02:30 am</p>
                                <h2>Nike Air Max</h2>
                              </div>
                            </div>
                            <div className="order-quantity">
                              <p>x1</p>
                            </div>
                            <div className="order-price">
                              <p>$125.00</p>
                            </div>
                            <div className="status confirmed">
                              <p>Confirmed</p>
                              <Link
                                onClick={() => setHidden(true)}
                                className="d-none d-xl-block"
                              >
                                <span>
                                  <ChatIcon />
                                </span>
                              </Link>
                              <Link
                                onClick={() => setModalChatShow(true)}
                                className="d-block d-xl-none"
                              >
                                <span>
                                  <ChatIcon />
                                </span>
                              </Link>
                            </div>
                          </div>

                          <div className="order-card">
                            <div className="img-title-div">
                              <img
                                src={orderimg1}
                                alt="orderimg"
                                className="img-fluid"
                              />
                              <div className="title-date">
                                <p>16 March 2022 - 02:30 am</p>
                                <h2>Nike Air Max</h2>
                              </div>
                            </div>
                            <div className="order-quantity">
                              <p>x1</p>
                            </div>
                            <div className="order-price">
                              <p>$125.00</p>
                            </div>
                            <div className="status pending">
                              <p>Pending</p>
                              {/* <Link><span><ChatIcon/></span></Link> */}
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade past-orders"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <Link onClick={() => setModalShow(true)}>
                            <div className="order-card">
                              <div className="img-title-div">
                                <img
                                  src={orderimg1}
                                  alt="orderimg"
                                  className="img-fluid"
                                />
                                <div className="title-date">
                                  <p>16 March 2022 - 02:30 am</p>
                                  <h2>Nike Air Max</h2>
                                </div>
                              </div>
                              <div className="order-quantity">
                                <p>x1</p>
                              </div>
                              <div className="order-price">
                                <p>$125.00</p>
                              </div>
                            </div>
                          </Link>
                          <Link onClick={() => setModalShow(true)}>
                            <div className="order-card">
                              <div className="img-title-div">
                                <img
                                  src={orderimg1}
                                  alt="orderimg"
                                  className="img-fluid"
                                />
                                <div className="title-date">
                                  <p>16 March 2022 - 02:30 am</p>
                                  <h2>Nike Air Max</h2>
                                </div>
                              </div>
                              <div className="order-quantity">
                                <p>x1</p>
                              </div>
                              <div className="order-price">
                                <p>$125.00</p>
                              </div>
                            </div>
                          </Link>
                          <Link onClick={() => setModalShow(true)}>
                            <div className="order-card">
                              <div className="img-title-div">
                                <img
                                  src={orderimg1}
                                  alt="orderimg"
                                  className="img-fluid"
                                />
                                <div className="title-date">
                                  <p>16 March 2022 - 02:30 am</p>
                                  <h2>Nike Air Max</h2>
                                </div>
                              </div>
                              <div className="order-quantity">
                                <p>x1</p>
                              </div>
                              <div className="order-price">
                                <p>$125.00</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* Tab Content End */}
                    </div>
                  </div>
                  <OrderCompletedPopup
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    closedModal={closedModal}
                  />
                </Col>

                <Col xs={12} sm={12} md={3} lg={3} xl={4} xxl={4}>
                  {hidden ? <StoreChat setHidden={setHidden} /> : null}
                </Col>
              </Row>
            </Container>
            {modalChatShow == true && (
              <StoreChatPopup
                setModalChatShow={setModalChatShow}
                modalChatShow={modalChatShow}
              />
            )}
          </section>
        </div>
      </Layout>
    </>
  );
};

export default BrandCards;
