import React from 'react'
import authsideimage from '../../assets/images/loginbannerside.png'

const AuthLayout = ({ children }) => {
  return (
    <div className="authbodyarea bgimage">
      <div className="container-fluid h-100 ">
        <div className="row h-100">
          <div className="col-12 mobile-d-none col-md-6 col-lg-6 col-xl-7 ps-0 bg-img-auth">
            
          </div>
          <div className="col-12 col-md-8 col-lg-6 col-xl-5  col-sm-12  m-auto ">
            <div className="my-auto">
              {children}
            </div>

          </div>
        </div>
      </div>
    </div>







  )
}

export default AuthLayout