import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { NavLink, useNavigate } from "react-router-dom";
import { Form,Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../constants/app-constants";

const VerificationForm = () => {
  const [otp, setOtp] = useState("");
  const [oerror, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  
  const handleOtpChange = (otp) => {
    setOtp(otp);
    if (otp.length === 0) {
      setError(true);
      setErrorMessage("Please Enter OTP code");
    } else if (otp.length > 0 && otp.length < 4) {
      setError(true);
      setErrorMessage("OTP code is incomplete");
    } else {
      setError(false);
    }
  };

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if (otp.length === 0) {
      setError(true);
      setErrorMessage("Please Enter OTP code");
    } else if (otp.length > 0 && otp.length < 4) {
      setError(true);
      setErrorMessage("OTP code is incomplete");
    } else {
      navigate("/signin")
    }
  };

  
  return (
    <div className="authbbox">
      <h2>Account Verification</h2>

      <div className="form-width">
      <p className="para f-18">Enter the 4 digit code you received on email</p>
      <div class="optformarea text-start">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <OtpInput
            className="otpinputfeild mb-4"
            value={otp}
            onChange={(otp) => handleOtpChange(otp)}
            numInputs={4}
            isInputNum={true}
            separator={<span>-</span>}
          />
          {oerror && <small className="text-red ms-1">{errorMessage}</small>}

          <Button type="submit" className="w-100 mt-3 button d-block btn-solid-blue mg-tb-40">
            Verify
          </Button>
        </Form>
      </div>
      </div>


      <p>
        Resend in <NavLink to="#" className="linkgreen"> 00:30</NavLink>
      </p>
    </div>
  );
};

export default VerificationForm;
