import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Card from "react-bootstrap/Card";

import SideMenu from "../components/SideMenu";

import ad1 from "../assets/images/advertisement/ad1.png";
import add2 from "../assets/images/advertisement/ad2.png";
import ad3 from "../assets/images/advertisement/ad3.png";
import ad4 from "../assets/images/advertisement/ad4.png";

import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";

const Setting = () => {
  return (
    <>
      <Layout>
        <Breadcrumbs pagename="Advertisement Page" />
        <div className="mainpadding">
          <section className="main-privacy-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  xl={3}
                  xxl={2}
                  md={3}
                  sm={12}
                  className="sidebar-class-col"
                >
                  <SideMenu />
                </Col>

                <Col xs={12} lg={9} xl={9} xxl={10}>
                  <div className="main-advertisement-section">
                    <Row>
                      <Col xs={12} md={6} xl={4} xxl={3} className="mb-4">
                        <NavLink to="/productdetailpage">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={ad1}
                              className="img-fluid"
                            />
                            <Card.Body>
                              <div className="exipry-section">
                                <p className="expiry">
                                  Expiry: 06 September 2022
                                </p>
                                <p className="valid">Valid</p>
                              </div>
                              <Card.Title>Ad Shoe</Card.Title>
                              <Card.Text>Nike Air Max</Card.Text>
                              <Card.Text className="small-text">
                                I loved this Shoes so much as soon as xyz
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </NavLink>
                      </Col>
                      <Col xs={12} md={6} xl={4} xxl={3} className="mb-4">
                        <NavLink to="/productdetailpage">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={ad1}
                              className="img-fluid"
                            />
                            <Card.Body>
                              <div className="exipry-section">
                                <p className="expiry">
                                  Expiry: 06 September 2022
                                </p>
                                <p className="valid">Valid</p>
                              </div>
                              <Card.Title>Ad Shoe</Card.Title>
                              <Card.Text>Nike Air Max</Card.Text>
                              <Card.Text className="small-text">
                                I loved this Shoes so much as soon as xyz
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </NavLink>
                      </Col>
                      <Col xs={12} md={6} xl={4} xxl={3} className="mb-4">
                        <NavLink to="/productdetailpage">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={ad1}
                              className="img-fluid"
                            />
                            <Card.Body>
                              <div className="exipry-section">
                                <p className="expiry">
                                  Expiry: 06 September 2022
                                </p>
                                <p className="expired">Expired</p>
                              </div>
                              <Card.Title>Ad Shoe</Card.Title>
                              <Card.Text>Nike Air Max</Card.Text>
                              <Card.Text className="small-text">
                                I loved this Shoes so much as soon as xyz
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </NavLink>
                      </Col>
                      <Col xs={12} md={6} xl={4} xxl={3} className="mb-4">
                        <NavLink to="/productdetailpage">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={ad1}
                              className="img-fluid"
                            />
                            <Card.Body>
                              <div className="exipry-section">
                                <p className="expiry">
                                  Expiry: 06 September 2022
                                </p>
                                <p className="expired">Expired</p>
                              </div>
                              <Card.Title>Ad Shoe</Card.Title>
                              <Card.Text>Nike Air Max</Card.Text>
                              <Card.Text className="small-text">
                                I loved this Shoes so much as soon as xyz
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </NavLink>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Setting;
