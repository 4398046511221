import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
// Import Swiper styles
import "swiper/css";

// Import Swiper styles
import "swiper/css";

function StoreListing(props) {
  let dataarrays = props.dataarrays;

  return (
    <>
      <section className="storehomeboxes ">
        <div className="mainpadding">
          <div className="container-fluid">
            <Row>
              <Col sm={12}>
                <div className="product-nav-heading">
                  <h2>{props.brandname}</h2>
                  <div className="slider-btns">
                    <NavLink to="/BrandDetailPage" className="view-button">View All</NavLink>
                    <div className="slider-arrow-btns">
                      <a className="slider-btn" id={props.slidenavprev}>
                        <svg
                          width="9"
                          height="17"
                          viewBox="0 0 9 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.98214 17C8.16844 17 8.35474 16.9314 8.50182 16.7843C8.78618 16.4999 8.78618 16.0293 8.50182 15.7449L2.10874 9.35184C1.63809 8.88118 1.63809 8.11637 2.10874 7.64571L8.50182 1.25263C8.78618 0.968277 8.78618 0.497621 8.50182 0.213266C8.21747 -0.0710887 7.74681 -0.0710887 7.46246 0.213266L1.06938 6.60634C0.569304 7.10642 0.28495 7.78299 0.28495 8.49877C0.28495 9.21456 0.5595 9.89113 1.06938 10.3912L7.46246 16.7843C7.60954 16.9216 7.79584 17 7.98214 17Z"
                            fill="#818181"
                          />
                        </svg>
                      </a>
                      <a className="slider-btn" id={props.slidenavnext}>
                        <svg
                          width="9"
                          height="17"
                          viewBox="0 0 9 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.01786 17C0.831559 17 0.645258 16.9314 0.498178 16.7843C0.213823 16.4999 0.213823 16.0293 0.498178 15.7449L6.89126 9.35184C7.36191 8.88118 7.36191 8.11637 6.89126 7.64571L0.498178 1.25263C0.213823 0.968277 0.213823 0.497621 0.498178 0.213266C0.782533 -0.0710887 1.25319 -0.0710887 1.53754 0.213266L7.93062 6.60634C8.4307 7.10642 8.71505 7.78299 8.71505 8.49877C8.71505 9.21456 8.4405 9.89113 7.93062 10.3912L1.53754 16.7843C1.39046 16.9216 1.20416 17 1.01786 17Z"
                            fill="#818181"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="row">
              <div className="col-md-12">
                <div className="storearea">
                  <div className="storeslider">
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={5}
                      navigation={{
                        nextEl: `#${props.slidenavnext}`,
                        prevEl: `#${props.slidenavprev}`,
                      }}
                      modules={[Navigation]}
                      onSlideChange={() => console.log("slide change")}
                      onSwiper={(swiper) => console.log(swiper)}
                      loop= {true}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 50,
                        },
                        576: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        992: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },

                        1200: {
                          slidesPerView: 5,
                          spaceBetween: 30,
                        },
                      }}
                    >
                      {dataarrays.map((els, i) => (
                        <SwiperSlide className="">
                          <Card className="product-box" key={i}>
                            <div className="cardimg">
                            <Link to="/productdetailpage">
                              <Card.Img
                                variant="top"
                                src={els.src}
                                alt={els.alt}
                                className="img-fluid"
                              />
                              </Link>
                            </div>

                            <Card.Body>
                              <Card.Title>{els.Title}</Card.Title>
                              <Card.Text>{els.Price}</Card.Text>
                            </Card.Body>
                          </Card>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StoreListing;
