
import {React, useLayoutEffect} from "react";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import HomePage from "../../pages/HomePage";
import AboutUs from "../../components/About/AboutUs";
import AuthLayout from "../../layouts/auth/AuthLayout";
import SignIn from "../../components/auth/SignIn";
import SignUp from "../../components/auth/SignUp";
import PasswordVerification from "../../components/auth/PasswordVerification";
import VerificationForm from "../../components/auth/VerificationForm";
import ResetPassword from "../../components/auth/ResetPassword";
import RecoverPassword from "../../components/auth/RecoverPassword";
import SelectYourRole from "../../components/auth/SelectYourRole";
import BrandCard from "../../components/auth/BrandCard";
import Brands from "../../pages/Brands";
import BrandDetailPage from "../../components/Product/BrandDetailPage";

// My screens
import ProductDetailPage from "../../components/Product/ProductDetailPage";
import EditProfilePage from "../../pages/EditProfilePage";
import BrandCardPage from "../../pages/BrandCardPage";
import WalletPage from "../../pages/WalletPage";
import OrderReviewPage from "../../pages/OrderReviewPage";
import ChangePasswordPage from "../../pages/ChangePasswordPage";
import TermsPage from "../../pages/TermsPage";
import PrivacyPage from "../../pages/PrivacyPage";
import AdvertisementPage from "../../pages/AdvertisementPage";
import MyOrders from "../../pages/MyOrders";
import ShoppingCart from "../../pages/ShoppingCart";
import PaymentMethodPage from "../../pages/PaymentMethodPage";

import LogoutAlert from "../../components/Modals/LogoutAlert";
import NotificationPopup from "../../components/Modals/NotificationPopup";
import MyProfilePopup from "../../components/Modals/MyProfilePopup";
import DeleteAlert from "../../components/Modals/DeleteAlert";
import OrderPlacedPopup from "../../components/Modals/OrderPlacedPopup";
import OrderCompletedPopup from "../../components/Modals/OrderCompletedPopup";
import FilterPopup from "../../components/Modals/FilterPopup";
import SearchPopup from "../../components/Modals/SearchPopup";

import Splashscreen from "../../components/splash/splashscreen";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import TermsConditions from "../../pages/TermsConditions";
import Contact from "../../pages/ContactUs";
import { useEffect } from "react";

const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    // Scroll to top if path changes
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        document.body.classList.remove("active");
    }, [ navigate]);

  return (
    <Routes>
      {/*<Route path="/" element={<AuthLayout children={<SignIn  />} />} />*/}

      <Route path="/signin" element={<AuthLayout children={<SignIn />} />} />
      <Route path="/signup" element={<AuthLayout children={<SignUp />} />} /> 
      <Route
        path="/verificationform"
        element={<AuthLayout children={<VerificationForm />} />}
      />
      <Route
        path="/passwordverification"
        element={<AuthLayout children={<PasswordVerification />} />}
      />
      <Route
        path="/ResetPassword"
        element={<AuthLayout children={<ResetPassword />} />}
      />
      <Route
        path="/RecoverPassword"
        element={<AuthLayout children={<RecoverPassword />} />}
      />
      <Route
        path="/SelectYourRole"
        element={<AuthLayout children={<SelectYourRole />} />}
      />
      <Route
        path="/BrandCard"
        element={<AuthLayout children={<BrandCard />} />}
      />

      <Route path="/Contact" element={<Contact />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/TermsConditions" element={<TermsConditions />} />

      <Route path="/brands" element={<Brands />} />
      <Route path="/about" element={<AboutUs />} />

      <Route path="/home" exact element={<HomePage isShow={true}/>} />

      {/* My screens */}
      <Route path="/BrandDetailPage" element={<BrandDetailPage isShow={true}/>} />
      <Route path="/ProductDetailPage" element={<ProductDetailPage />} />
      <Route path="/EditProfilePage" element={<EditProfilePage />} />
      <Route path="/BrandCardPage" element={<BrandCardPage />} />
      <Route path="/WalletPage" element={<WalletPage />} />
      <Route path="/OrderReviewPage" element={<OrderReviewPage />} />

      <Route path="/AdvertisementPage" element={<AdvertisementPage />} />
      <Route path="/MyOrders" element={<MyOrders />} />
      <Route path="/Shoppingcart" element={<ShoppingCart />} />
      <Route path="/PaymentMethodPage" element={<PaymentMethodPage />} />

      {/* Settings */}
      <Route path="/settings/ChangePasswordPage" element={<ChangePasswordPage />} />
      <Route path="/settings/TermsPage" element={<TermsPage />} />
      <Route path="/settings/PrivacyPage" element={<PrivacyPage />} />


      <Route path="/Logoutpopup" element={<LogoutAlert />} />
      <Route path="/Notificationpopup" element={<NotificationPopup />} />
      <Route path="/MyProfilepopup" element={<MyProfilePopup />} />
      <Route path="/Deletepopup" element={<DeleteAlert />} />
      <Route path="/OrderPlacedPopup" element={<OrderPlacedPopup />} />
      <Route path="/OrderCompletedPopup" element={<OrderCompletedPopup />} />
      <Route path="/SearchPopup" element={<SearchPopup />} />

      <Route path="/" element={<Splashscreen />} />
    </Routes>
  )
}

export default Navigation