import React from "react";
import Layout from "../layouts/Layout";
import BannerSection from "../components/home/BannerSection";
import StoreListing from "../components/Product/StoreListing";
import HrLineSpace from "../components/HrLineSpace";
import Nikeimage1 from "../assets/images/nike-store/nike1.png";
import Nikeimage2 from "../assets/images/nike-store/nike2.png";
import Nikeimage3 from "../assets/images/nike-store/nike3.png";
import Nikeimage4 from "../assets/images/nike-store/nike4.png";
import Nikeimage5 from "../assets/images/nike-store/nike5.png";

import Gucciimage1 from "../assets/images/guccistore/gucci1.png";
import Gucciimage2 from "../assets/images/guccistore/gucci2.png";
import Gucciimage3 from "../assets/images/guccistore/gucci3.png";
import Gucciimage4 from "../assets/images/guccistore/gucci4.png";
import Gucciimage5 from "../assets/images/guccistore/gucci5.png";

import HomeAdvertisement from "../components/Advertisement/HomeAdvertisement";
import TrendingProducts from "../components/TrendingProducts/TrendingProducts";

import trendingproducts1 from "../assets/images/tendingproducts/trending-1.png";
import trendingproducts2 from "../assets/images/tendingproducts/trending-2.png";
import trendingproducts3 from "../assets/images/tendingproducts/trending-3.png";
import trendingproducts4 from "../assets/images/tendingproducts/trending-4.png";
import trendingproducts5 from "../assets/images/tendingproducts/trending-5.png";
import trendingproducts6 from "../assets/images/tendingproducts/trending-6.png";
import trendingproducts7 from "../assets/images/tendingproducts/trending-7.png";
import trendingproducts8 from "../assets/images/tendingproducts/trending-8.png";
import trendingproducts9 from "../assets/images/tendingproducts/trending-9.png";
import trendingproducts10 from "../assets/images/tendingproducts/trending-10.png";
import trendingproducts11 from "../assets/images/tendingproducts/trending-11.png";
import trendingproducts12 from "../assets/images/tendingproducts/trending-12.png";

const Otherdata = [
  {
    src: Gucciimage1,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Gucciimage2,
    alt: "Gucciimage3",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Gucciimage3,
    alt: "Gucciimage4",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Gucciimage4,
    alt: "Gucciimage5",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Gucciimage5,
    alt: "Gucciimage3",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage3,
    alt: "Gucciimage4",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage4,
    alt: "Gucciimage5",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Gucciimage2,
    alt: "Gucciimage3",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage3,
    alt: "Gucciimage4",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage4,
    alt: "Gucciimage5",
    Title: "Product Name",
    Price: "$200",
  },
];

const Productdata = [
  {
    src: Nikeimage1,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage2,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage3,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage4,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },
  {
    src: Nikeimage5,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },

  {
    src: Nikeimage1,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },

  {
    src: Nikeimage3,
    alt: "Image01",
    Title: "Product Name",
    Price: "$200",
  },
];

const TrendingProduct = [
  {
    id: 1,
    src: trendingproducts1,
    title: "Nike Store",
    price: "$20.00",
  },
  {
    id: 2,
    src: trendingproducts2,
    title: "Gucci",
    price: "$740.00",
  },
  {
    id: 3,
    src: trendingproducts3,
    title: "Louis Vuitton",
    price: "$400.00",
  },
  {
    id: 4,
    src: trendingproducts4,
    title: "Hermes",
    price: "$110.00",
  },
  {
    id: 5,
    src: trendingproducts5,
    title: "Zalando",
    price: "$20.00",
  },
  {
    id: 6,
    src: trendingproducts6,
    title: "Adidas",
    price: "$320.00",
  },
  {
    id: 7,
    src: trendingproducts7,
    title: "Tiffany & Co.",
    price: "$750.00",
  },
  {
    id: 8,
    src: trendingproducts8,
    title: "Zara",
    price: "$901.00",
  },
  {
    id: 9,
    src: trendingproducts9,
    title: "H&M",
    price: "$740.00",
  },
  {
    id: 10,
    src: trendingproducts10,
    title: "Cartier",
    price: "$70.00",
  },
];

const HomePage = ({isShow}) => {
  return (
    <Layout isShow={isShow}>
      <BannerSection />
      <div className="main-home-container margin-bottom-150">
      <section className="margin-top-40">
        <StoreListing
          dataarrays={Productdata}
          brandname="Nike Store"
          slidenavnext="js-next1"
          slidenavprev="js-prev1"
        />
      </section>
      <HrLineSpace />

      <StoreListing
        dataarrays={Otherdata}
        brandname="Gucci Store"
        slidenavnext="js-next2"
        slidenavprev="js-prev2"
      />

      <HomeAdvertisement />

      <TrendingProducts title="Trending Products" dataarrays={TrendingProduct} />
      </div>

    </Layout>
  );
};

export default HomePage;
