import React from 'react'
import Header from './Header';
import Footer from "./Footer";

const Layout = ({children , isShow}) => {
  return (
<>
<Header isShow={isShow}/>
<main> 
    {children}  </main>
 <Footer />
</>
  )
}

export default Layout