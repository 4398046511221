import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SideMenu from "../components/SideMenu";

import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";

import nike from "../assets/images/brandcards/nike.png";
import handm from "../assets/images/brandcards/h&m.png";
import gap from "../assets/images/brandcards/gap.png";
import levis from "../assets/images/brandcards/levis.png";
import { FaPlus } from "react-icons/fa";

const BrandCards = () => {
  return (
    <>
      <Layout>
        <Breadcrumbs pagename="Brand Cards" />
        <div className="mainpadding">
          <section class="main-brand-card margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  xl={3}
                  xxl={2}
                  md={3}
                  sm={12}
                  className="mb-xl-auto sidebar-class-col"
                >
                  <SideMenu />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  xl={5}
                  xxl={4}
                  className="brand-section-col"
                >
                  <div className="brand-card-section">
                    <Row>
                      <Col>
                        <h6>Brand Cards</h6>
                        <div className="border-bottomm"></div>
                      </Col>
                    </Row>

                    <div className="brand-cards">
                      <Row>
                        <Col
                          xs={12}
                          sm={3}
                          md={2}
                          className="my-auto brand-logo-div"
                        >
                          <img src={nike} alt="nike" />
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={7}
                          className="brand-details-div"
                        >
                          <h2>Nike Store</h2>
                          <ul>
                            <li>
                              <span>Serial #:</span> Ni-123
                            </li>
                            <li>
                              <span>Amount:</span> $120
                            </li>
                            <li>
                              <span>Expiry:</span> 24 July 2022
                            </li>
                          </ul>
                        </Col>
                        <Col xs={12} sm={3} className="my-auto brand-redeem">
                          <a to="/">Redeem</a>
                        </Col>
                      </Row>
                    </div>

                    <div className="brand-cards">
                      <Row>
                        <Col
                          xs={12}
                          sm={3}
                          md={2}
                          className="my-auto brand-logo-div"
                        >
                          <img src={handm} alt="nike" />
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={7}
                          className="brand-details-div"
                        >
                          <h2>Nike Store</h2>
                          <ul>
                            <li>
                              <span>Serial #:</span> Ni-123
                            </li>
                            <li>
                              <span>Amount:</span> $120
                            </li>
                            <li>
                              <span>Expiry:</span> 24 July 2022
                            </li>
                          </ul>
                        </Col>
                        <Col xs={12} sm={3} className="my-auto brand-redeem">
                          <a to="/">Redeem</a>
                        </Col>
                      </Row>
                    </div>

                    <div className="brand-cards">
                      <Row>
                        <Col
                          xs={12}
                          sm={3}
                          md={2}
                          className="my-auto brand-logo-div"
                        >
                          <img src={gap} alt="nike" />
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={7}
                          className="brand-details-div"
                        >
                          <h2>Nike Store</h2>
                          <ul>
                            <li>
                              <span>Serial #:</span> Ni-123
                            </li>
                            <li>
                              <span>Amount:</span> $120
                            </li>
                            <li>
                              <span>Expiry:</span> 24 July 2022
                            </li>
                          </ul>
                        </Col>
                        <Col xs={12} sm={3} className="my-auto brand-redeem">
                          <a to="/">Redeem</a>
                        </Col>
                      </Row>
                    </div>

                    <div className="brand-cards">
                      <Row>
                        <Col
                          xs={12}
                          sm={3}
                          md={2}
                          className="my-auto brand-logo-div"
                        >
                          <img src={levis} alt="nike" />
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={7}
                          className="brand-details-div"
                        >
                          <h2>Nike Store</h2>
                          <ul>
                            <li>
                              <span>Serial #:</span> Ni-123
                            </li>
                            <li>
                              <span>Amount:</span> $120
                            </li>
                            <li>
                              <span>Expiry:</span> 24 July 2022
                            </li>
                          </ul>
                        </Col>
                        <Col xs={12} sm={3} className="my-auto brand-redeem">
                          <a to="/">Redeem</a>
                        </Col>
                      </Row>
                    </div>

                    <div className="add-new-btn">
                      {/* <a to="#">
                        <FaPlus />
                        Add New
                      </a> */}
                      <a to="#">
                        +<span>Add New</span>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default BrandCards;
