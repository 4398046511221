import React from "react";
import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import { Link } from "react-router-dom";
import brand1 from "../assets/images/brands/brand1.png";
import brand2 from "../assets/images/brands/brand2.png";
import brand3 from "../assets/images/brands/brand3.png";
import brand4 from "../assets/images/brands/brand4.png";
import brand5 from "../assets/images/brands/brand5.png";
import brand6 from "../assets/images/brands/brand6.png";
import brand7 from "../assets/images/brands/brand7.png";
import brand8 from "../assets/images/brands/brand8.png";
import brand9 from "../assets/images/brands/brand9.png";
import brand10 from "../assets/images/brands/brand10.png";
import brand11 from "../assets/images/brands/brand11.png";
import brand12 from "../assets/images/brands/brand12.png";
import brand13 from "../assets/images/brands/brand13.png";
import brand14 from "../assets/images/brands/brand14.png";
import brand15 from "../assets/images/brands/brand15.png";
import brand16 from "../assets/images/brands/brand16.png";
import brand17 from "../assets/images/brands/brand17.png";
import brand18 from "../assets/images/brands/brand18.png";
import brand19 from "../assets/images/brands/brand19.png";
import brand20 from "../assets/images/brands/brand20.png";
import brand21 from "../assets/images/brands/brand21.png";
import brand22 from "../assets/images/brands/brand22.png";
import brand23 from "../assets/images/brands/brand23.png";
import brand24 from "../assets/images/brands/brand24.png";
import brand25 from "../assets/images/brands/brand25.png";
import brand26 from "../assets/images/brands/brand26.png";
import brand27 from "../assets/images/brands/brand27.png";
import brand28 from "../assets/images/brands/brand28.png";
import brand29 from "../assets/images/brands/brand29.png";
import brand30 from "../assets/images/brands/brand30.png";

const BrandCard = [
  {
    id: 1,
    src: brand1,
    title: "Nike Store",
  },
  {
    id: 2,
    src: brand2,
    title: "Gucci",
  },
  {
    id: 3,
    src: brand3,
    title: "Louis Vuitton",
  },
  {
    id: 4,
    src: brand4,
    title: "Hermes",
  },
  {
    id: 5,
    src: brand5,
    title: "Zalando",
  },
  {
    id: 6,
    src: brand6,
    title: "Adidas",
  },
  {
    id: 7,
    src: brand7,
    title: "Tiffany & Co.",
  },
  {
    id: 8,
    src: brand8,
    title: "Zara",
  },
  {
    id: 9,
    src: brand9,
    title: "H&M",
  },
  {
    id: 10,
    src: brand10,
    title: "Cartier",
  },
  {
    id: 11,
    src: brand11,
    title: "Lululemon",
  },
  {
    id: 12,
    src: brand12,
    title: "Moncler",
  },
  {
    id: 13,
    src: brand13,
    title: "Chanel",
  },
  {
    id: 14,
    src: brand14,
    title: "Rolex",
  },
  {
    id: 15,
    src: brand15,
    title: "Patek Philippe",
  },
  {
    id: 16,
    src: brand16,
    title: "Prada",
  },
  {
    id: 17,
    src: brand17,
    title: "Uniqlo",
  },
  {
    id: 18,
    src: brand18,
    title: "Swarovski",
  },
  {
    id: 19,
    src: brand19,
    title: "Burberry",
  },
  {
    id: 20,
    src: brand20,
    title: "Polo Ralph Lauren",
  },
  {
    id: 21,
    src: brand21,
    title: "Tom Ford",
  },
  {
    id: 22,
    src: brand22,
    title: "The North Face ",
  },
  {
    id: 23,
    src: brand23,
    title: "Levi’s",
  },
  {
    id: 24,
    src: brand24,
    title: "Victoria’s Secret",
  },
  {
    id: 25,
    src: brand25,
    title: "Next",
  },
  {
    id: 26,
    src: brand26,
    title: "Michael Kors",
  },
  {
    id: 27,
    src: brand27,
    title: "Skechers",
  },
  {
    id: 28,
    src: brand28,
    title: "TJ Maxx",
  },
  {
    id: 29,
    src: brand29,
    title: "Ray Ban",
  },
  {
    id: 30,
    src: brand30,
    title: "Puma",
  },
];
function Brands(props) {
  return (
    <Layout>
      <Breadcrumbs pagename="Brands" />
      <div className="brand-container margin-bottom-150">
        <div className="mainpadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  {BrandCard.map((el, i) => (
                    <div className="col-xs-12 col-md-6 col-lg-4 col-xl-2">
                      <div className="whitebox-area ">
                        <Link to="/BrandDetailPage">
                          <img src={el.src} alt="" />
                          <h2>{el.title}</h2>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Brands;
