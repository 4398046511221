import React from "react";
import { Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";

import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../constants/app-constants";
function BrandCard(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("password");

  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/Home")
  };
  return (
    <div className="authbbox">
      <h2>Brand Card</h2>
      <div className="form-width">
        <p className="mb-5 f-18">Enter your brand card details</p>

        <div className="form-feild text-start">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel
              label="Enter Your Brand Hash Number"
              className="inputfeildarea"
            >
              <Form.Control
                type="text"
                placeholder="Enter Your Brand Hash Number"
                {...register("cardNumber", {
                  required: {
                    value: true,
                    message: VALIDATIONS_TEXT.CARD_NUMBER_REQUIRED,
                  },
                  // minLength: {
                  //   value: VALIDATIONS.CARD_NUMBER,
                  //   message: VALIDATIONS_TEXT.CARD_NUMBER_MIN,
                  // },
                  maxLength: {
                    value: VALIDATIONS.CARD_NUMBER,
                    message: VALIDATIONS_TEXT.CARD_NUMBER_MAX,
                  },
                  // pattern: {
                  //   value:
                  //     /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
                  //   message: VALIDATIONS_TEXT.INVALID_FORMAT,
                  // },
                })}
              />
              {errors.cardNumber && (
                <small className="text-red">{errors.cardNumber.message}</small>
              )}
            </FloatingLabel>

            <div className="findcontent">
              <span class="findcontenticon">
                <svg
                  width="22"
                  height="23"
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.97675 7.10986C9.97675 6.54473 10.4349 6.0866 11 6.0866C11.5651 6.0866 12.0233 6.54473 12.0233 7.10986C12.0233 7.67499 11.5651 8.13311 11 8.13311C10.4349 8.13311 9.97674 7.67498 9.97675 7.10986Z"
                    fill="#999999"
                  />
                  <path
                    d="M10.2326 16.3192C10.2326 16.743 10.5762 17.0866 11 17.0866C11.4238 17.0866 11.7674 16.743 11.7674 16.3192L11.7674 10.1796C11.7674 9.75578 11.4238 9.41218 11 9.41218C10.5762 9.41218 10.2326 9.75578 10.2326 10.1796L10.2326 16.3192Z"
                    fill="#999999"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 22.2029C17.0751 22.2029 22 17.278 22 11.2029C22 5.12775 17.0751 0.20288 11 0.20288C4.92487 0.202879 1.49276e-06 5.12775 9.61651e-07 11.2029C4.30546e-07 17.278 4.92487 22.2029 11 22.2029ZM20.4651 11.2029C20.4651 16.4303 16.2274 20.668 11 20.668C5.77256 20.668 1.53488 16.4303 1.53488 11.2029C1.53488 5.97544 5.77256 1.73776 11 1.73776C16.2274 1.73776 20.4651 5.97544 20.4651 11.2029Z"
                    fill="#999999"
                  />
                </svg>
              </span>
              Find the hash number below the QR Code on your brand card!
            </div>

            <input
              type="submit"
              className="button   w-100 btn-solid-blue"
              value="Continue"
            />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default BrandCard;
