import React, { useState } from "react";
import { Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import EyeClose from "../../assets/images/svg/close-eye.svg";
import OpenEye from "../../assets/images/svg/open-eye.svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
} from "../../constants/app-constants";
function ResetPassword(props) {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");

  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/SignIn");
  };

  return (
    <div className="authbbox">
      <h2>Reset Password</h2>

    <div className="form-width">
    <p className="pad-lf50 f-18">
        Your new password must be different from previous used passwords
      </p>

      <div className="form-feild text-start">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FloatingLabel label="Password" className="inputfeildarea">
            <Form.Control
              placeholder="Password"
              type={!showCurrentPassword ? "password" : "text"}
              maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
              {...register("password", {
                minLength: {
                  value: VALIDATIONS.PASSWORD_MIN,
                  message: VALIDATIONS_TEXT.PASSWORD_MIN,
                },
                maxLength: {
                  value: VALIDATIONS.PASSWORD_MAX,
                  message: VALIDATIONS_TEXT.PASSWORD_MAX,
                },
                required: {
                  value: true,
                  message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                },
                pattern: {
                  value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                  message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                },
              })}
            />
            <span className="iconfeild">
              {showCurrentPassword ? (
                <span
                  className="toggle-password"
                  onClick={() => setShowCurrentPassword(false)}
                >
                  <img src={OpenEye} alt="" title="" />
                </span>
              ) : (
                <span className="toggle-password">
                  <img
                    src={EyeClose}
                    alt=""
                    title=""
                    onClick={() => setShowCurrentPassword(true)}
                  />
                </span>
              )}
            </span>

          </FloatingLabel>
          
          {errors.password && (
            <small className="text-red">{errors.password.message}</small>
          )}

          <FloatingLabel label="Confirm Password  *" className="inputfeildarea">
            <Form.Control
              type={!showConfirmPassword ? "password" : "text"}
              placeholder="Confirm Password  *"
              maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
              {...register("password_confirmation", {
                minLength: {
                  value: VALIDATIONS.PASSWORD_MIN,
                  message: VALIDATIONS_TEXT.PASSWORD_MIN,
                },
                maxLength: {
                  value: VALIDATIONS.PASSWORD_MAX,
                  message: VALIDATIONS_TEXT.PASSWORD_MAX,
                },
                required: {
                  value: true,
                  message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED,
                },
                validate: (value) =>
                  value === password || "Password and Confirm Password must be same",
              })}
            />
            <span className="iconfeild">
              {showConfirmPassword ? (
                <span
                  className="toggle-password"
                  onClick={() => setShowConfirmPassword(false)}
                >
                  <img src={OpenEye} alt="" title="" />
                </span>
              ) : (
                <span className="toggle-password">
                  <img
                    src={EyeClose}
                    alt=""
                    title=""
                    onClick={() => setShowConfirmPassword(true)}
                  />
                </span>
              )}
            </span>

          </FloatingLabel>
          {errors.password_confirmation &&
            <small className='text-red'>{errors.password_confirmation.message}</small>}
          <input
            type="submit"
            className="button mt-4  w-100 btn-solid-blue"
            name="Change Password"
          />
        </Form>
      </div>
    </div>

    </div>
  );
}

export default ResetPassword;
