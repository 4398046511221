import React from "react";
import homelogoImage from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import applyIconImage from "../assets/images/appstore-btn.svg";
import playstoreImage from "../assets/images/playstore-btn.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import images from "../constants/images";
import { NavLink } from "react-router-dom";
function Footer(props) {
  return (
    <section className="footer">
      <div className="mainpadding">
        <section className="footer">
          <Container fluid>
            <Row>
              <div className="col-md-6 col-lg-4">
                <div className="footer-1">
                  <img
                    src={images.footerlogo}
                    alt="menu-img"
                    className="img-fluid"
                  />
                  <p>
                    This is dummy copy. It is not meant to be read. It has been
                    placed here solely to demonstrate the look and feel of
                    finished, typeset text.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="footer-2">
                  <div className="container-fluid">
                    <div className="row">
                      <h2 className="footer-heading">Quick Links</h2>
                      <div className="footerlists">
                        <div className="first-list">
                          <ul className="style-list">
                            <li>
                              <NavLink to="/home" className="anchor-style">
                                Home
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/about" className="anchor-style">
                                About
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/Brands" className="anchor-style">
                                Products
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/BrandCardPage" className="anchor-style">
                                Brands
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                        <div className="second-list">
                          <ul className="style-list">
                            <li>
                              <NavLink to="/Contact" className="anchor-style">
                                Contact Us
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/TermsConditions" className="anchor-style">
                                Terms & Conditions
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/PrivacyPolicy" className="anchor-style">
                                Privacy Policy
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="footer-3">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 ps-0">
                        <div className="myaccount">
                          <h2 className="footer-heading">My Account</h2>
                          <ul className="style-list">
                            <li>
                              <NavLink to="/EditProfilePage" className="anchor-style">
                                My Profile
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/Shoppingcart" className="anchor-style">
                                My Cart
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="app-section">
                          <h2 className="footer-heading">App</h2>
                          <NavLink to="">
                 
                            <img
                              src={images.appstore}
                              alt="app-img"
                              className="img-fluid"
                            />
                          </NavLink>
                          <NavLink to="">
                            <img
                              src={images.googleplay}
                              alt="google-img"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bottom-border"></div>

              <div className="copyright">
                <p>© Zombie Gift Kard, Inc. All rights reserved.</p>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </section>
  );
}

export default Footer;
