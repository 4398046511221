import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, NavLink } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import cartimg1 from "../assets/images/shoppingcart/shoppingcart1.png";

import { EditIcon } from "../constants/svg";
import { DeleteIcon, CartIcon } from "../constants/svg";
import DeleteAlert from "../components/Modals/DeleteAlert";

const ShoppingCart = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Layout>
        <Breadcrumbs parentPage="Store" subparent="Product Details" pagename="Shopping Cart" />
        <div className="mainpadding">
          <div className="main-cart-table-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col xxl={8} md={7} className="cart-table-div">
                  <div className="cart-table">
                    <Table>
                      <thead>
                        <tr>
                          <th>Products</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="pl-0">
                            <div className="order-img-title">
                              <img
                                src={cartimg1}
                                alt="cartimg"
                                className="img-fluid"
                              />
                              <div className="name-div">
                              <h2>Nike Air Max</h2>
                             
                              </div>
                           
                            </div>
                          </td>
                          <td>
                            <div className="quantity">
                              <p>x1</p>
                            </div>
                          </td>
                          <td>
                            <div className="price">
                              <p>$125.00</p>
                            </div>
                          </td>
                          <td>
                            <div className="total">
                              <p>$125.00</p>
                            </div>
                          </td>
                          <td>
                            <div className="edit-delete-btns">
                              <Link>
                                <EditIcon />
                              </Link>
                              <Link onClick={() => setModalShow(true)}>
                                <DeleteIcon />
                              </Link>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="pl-0">
                            <div className="order-img-title">
                              <img
                                src={cartimg1}
                                alt="cartimg"
                                className="img-fluid"
                              />
                              <h2>Nike Air Max</h2>
                            </div>
                          </td>
                          <td>
                            <div className="quantity">
                              <p>x1</p>
                            </div>
                          </td>
                          <td>
                            <div className="price">
                              <p>$125.00</p>
                            </div>
                          </td>
                          <td>
                            <div className="total">
                              <p>$125.00</p>
                            </div>
                          </td>
                          <td>
                            <div className="edit-delete-btns">
                              <Link>
                                <EditIcon />
                              </Link>
                              <Link>
                                <DeleteIcon />
                              </Link>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="pl-0">
                            <div className="order-img-title">
                              <img
                                src={cartimg1}
                                alt="cartimg"
                                className="img-fluid"
                              />
                              <h2>Nike Air Max</h2>
                            </div>
                          </td>
                          <td>
                            <div className="quantity">
                              <p>x1</p>
                            </div>
                          </td>
                          <td>
                            <div className="price">
                              <p>$125.00</p>
                            </div>
                          </td>
                          <td>
                            <div className="total">
                              <p>$125.00</p>
                            </div>
                          </td>
                          <td>
                            <div className="edit-delete-btns">
                              <Link>
                                <EditIcon />
                              </Link>
                              <Link>
                                <DeleteIcon />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <DeleteAlert
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  
                />

                <Col xxl={4} md={5} classNam="cart-btn-div">
                  <div className="amount-details">
                    <h1>Amount Details</h1>
                    <div className="checkout-total">
                      <p>
                        <span>Total Price</span> $500
                      </p>
                      <NavLink to="/PaymentMethodPage">
                        <span>
                          <CartIcon />
                        </span>
                        Check Out
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ShoppingCart;
