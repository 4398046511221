import React from "react";
import { Link } from "react-router-dom";
import advertisementBg from "../../assets/images/advbg.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function HomeAdvertisement(props) {
  return (
    
          <section
            className="main-margin advertisementarea margin-top-32"
            // style={{ backgroundImage: `url(${advertisementBg})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="contentheading">
                    <h2 className="wow   fadeInDown" data-wow-delay="0.12s">
                      Advertisement
                    </h2>
                    <p className="wow   fadeInUp" data-wow-delay="0.12s">
                      This is dummy copy. It is not meant to be read. It has
                      been placed here solely to demonstrate the look and feel
                      of finished, typeset text.
                    </p>
                    <Link to="/AdvertisementPage">View All</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

  );
}

export default HomeAdvertisement;
