import React, {useState} from "react";
import { Link } from "react-router-dom";
import Cross from "../../assets/images/icons/cross.svg";
import notification1 from "../../assets/images/notification/notification1.png";
import notification2 from "../../assets/images/notification/notification2.png";
import notification3 from "../../assets/images/notification/notification3.png";
import Modal from "react-bootstrap/Modal";
const NotificationPopup = (props) => {

  return (
    <>

        <Modal
          {...props}
    
       
          
          className="side-panel"
        >
          <section className="notification-popup">
            <div className="notification-heading-section">
              <h1>
                Notification <span>(2)</span>
              </h1>
              <div className="cross-icon">
                <Link to="#">
                  <img src={Cross} alt="cross" className="img-fluid" />
                </Link>
              </div>
            </div>
            <hr className="bottom-border" />

            <div className="main-notification">
              <Link>
                <div className="notification-card unread-bg">
                  <img
                    src={notification1}
                    alt="notification1"
                    className="img-fluid"
                  />
                  <div className="store-heading">
                    <h2>Nike Store</h2>
                    <p>Accepted your order Nike Air Max</p>
                  </div>
                  <p>1h 20m</p>
                </div>
              </Link>
              <Link>
                <div className="notification-card unread-bg">
                  <img
                    src={notification2}
                    alt="notification1"
                    className="img-fluid"
                  />
                  <div className="store-heading">
                    <h2>Nike Store</h2>
                    <p>Accepted your order Nike Air Max</p>
                  </div>
                  <p>1h 20m</p>
                </div>
              </Link>
              <Link>
                <div className="notification-card top-border">
                  <img
                    src={notification3}
                    alt="notification1"
                    className="img-fluid"
                  />
                  <div className="store-heading">
                    <h2>Nike Store</h2>
                    <p>Accepted your order Nike Air Max</p>
                  </div>
                  <p>1h 20m</p>
                </div>
              </Link>
              <Link>
                <div className="notification-card top-border">
                  <img
                    src={notification1}
                    alt="notification1"
                    className="img-fluid"
                  />
                  <div className="store-heading">
                    <h2>Nike Store</h2>
                    <p>Accepted your order Nike Air Max</p>
                  </div>
                  <p>1h 20m</p>
                </div>
              </Link>
              <Link>
                <div className="notification-card top-border">
                  <img
                    src={notification1}
                    alt="notification1"
                    className="img-fluid"
                  />
                  <div className="store-heading">
                    <h2>Nike Store</h2>
                    <p>Accepted your order Nike Air Max</p>
                  </div>
                  <p>1h 20m</p>
                </div>
              </Link>
              <Link>
                <div className="notification-card top-border">
                  <img
                    src={notification1}
                    alt="notification1"
                    className="img-fluid"
                  />
                  <div className="store-heading">
                    <h2>Nike Store</h2>
                    <p>Accepted your order Nike Air Max</p>
                  </div>
                  <p>1h 20m</p>
                </div>
              </Link>
            </div>
          </section>
        </Modal>
 
    </>
  );
};

export default NotificationPopup;
