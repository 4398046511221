import React from "react";

import { Link } from "react-router-dom";

function Breadcrumbs(props) {

  return (
    <section className="mainpadding">
      <div className="main-breadcrumbs-div">
      <div className="breadcrumbs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <ul>
                <li>
                  <Link to="/home">Home</Link>
                </li>
                {props.parentPage && (
                  <li>
                    <Link to={props.link}>{props.parentPage} </Link>
                  </li>
                )}

                {props.subparent && (
                  <li>
                    <Link to="#">{props.subparent} </Link>
                  </li>
                )}

                {props.secondsubpage && (
                  <li>
                    <Link to="#">{props.secondsubpage} </Link>
                  </li>
                )}

                <li className="activelinkgray">
                  <Link to="#">{props.pagename}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="filter-reset-btn">
        <button className={`${props.show ? "d-block": "d-none"}`}>Reset Filter</button>
      </div>
      </div>

    </section>
  );
}

export default Breadcrumbs;
