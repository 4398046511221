import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useForm } from "react-hook-form";
import EyeClose from "../../assets/images/svg/close-eye.svg";
import OpenEye from "../../assets/images/svg/open-eye.svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  PASSWORD_FORMAT,
  VALIDATIONS_TEXT,
} from "../../constants/app-constants";

const SignIn = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/BrandCard")
  };
  return (
    <div className="authbbox">
      <h2>Sign In</h2>
      <p className="f-18">Welcome back you‘ve been missed!</p>
      <div className="form-feild text-start form-width">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FloatingLabel label="Email address" className="inputfeildarea">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              maxLength={VALIDATIONS.EMAIL}
              {...register("email", {
                maxLength: {
                  value: VALIDATIONS.EMAIL,
                  message: VALIDATIONS_TEXT.EMAIL_MAX,
                },
                required: {
                  value: true,
                  message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                },
              })}
            />
            {errors.email && (
              <small className="text-red">{errors.email.message}</small>
            )}
          </FloatingLabel>

          <FloatingLabel label="Password" className="inputfeildarea">
            <Form.Control
              type={!showCurrentPassword ? "password" : "text"}
              placeholder="Password"
              maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
              {...register("password", {
                minLength: {
                  value: VALIDATIONS.PASSWORD_MIN,
                  message: VALIDATIONS_TEXT.PASSWORD_MIN,
                },
                maxLength: {
                  value: VALIDATIONS.PASSWORD_MAX,
                  message: VALIDATIONS_TEXT.PASSWORD_MAX,
                },
                required: {
                  value: true,
                  message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                },
                pattern: {
                  value: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
                  message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                },
              })}
            />
            <span className="iconfeild">
              {showCurrentPassword ? (
                <span
                  className="toggle-password"
                  onClick={() => setShowCurrentPassword(false)}
                >
                  <img src={OpenEye} alt="" title="" />
                </span>
              ) : (
                <span className="toggle-password">
                  <img
                    src={EyeClose}
                    alt=""
                    title=""
                    onClick={() => setShowCurrentPassword(true)}
                  />
                </span>
              )}
            </span>
          </FloatingLabel>
          {errors.password && (
            <small className="text-red">{errors.password.message}</small>
          )}

          <div className="right linkgreen">
            <NavLink to="/RecoverPassword" className="forget-mg linkgreen text-end d-block">
              Forgot Password?
            </NavLink>
          </div>

          <Button type="submit" className="button d-block btn-solid-blue w-100 m-auto">
            Sign In
          </Button>
        </Form>
      </div>

      <div className="continue-wrapper">or Continue with</div>

      <NavLink to="#" className=" d-block google-btn  ">
        <span className="icongoogle">
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.17786 10.9131C4.20113 10.9591 4.22809 11.0036 4.24683 11.0513C5.0033 12.9693 6.39845 14.128 8.42887 14.4539C9.6709 14.6658 10.948 14.4395 12.0415 13.8135C12.0861 13.7883 12.1346 13.7704 12.1811 13.7491L15.0081 15.943C14.9905 15.9657 14.9715 15.9873 14.9513 16.0077C13.7237 17.112 12.197 17.8285 10.5632 18.0671C10.3296 18.0816 10.0976 18.1158 9.86972 18.1693H8.51061C8.39474 18.1215 8.26958 18.1004 8.14445 18.1077C6.9198 17.9698 5.73757 17.5771 4.67383 16.9548C3.61009 16.3325 2.6884 15.4944 1.96804 14.4945C1.65652 14.0916 1.39622 13.6515 1.19312 13.1845C1.31347 13.0945 1.43468 13.0048 1.55418 12.9137C2.42921 12.247 3.30377 11.5801 4.17786 10.9131Z"
              fill="#129E3A"
            />
            <path
              d="M15.0084 15.9429L12.1815 13.749C12.4731 13.5395 12.7467 13.3061 12.9993 13.051C13.4662 12.5377 13.796 11.9149 13.9582 11.2403C13.9954 11.093 13.9639 11.0645 13.8202 11.0648C12.3567 11.0692 10.893 11.0699 9.42927 11.0671C9.17607 11.0671 9.20645 11.0955 9.20616 10.8451C9.20616 9.84028 9.20928 8.83547 9.2019 7.8309C9.2019 7.66967 9.23029 7.61544 9.40713 7.61572C12.0498 7.62216 14.6925 7.62216 17.3352 7.61572C17.4899 7.61572 17.5464 7.66592 17.5717 7.80983C17.6416 8.16837 17.6903 8.53073 17.7176 8.89501C17.6959 9.10548 17.706 9.31806 17.7476 9.52551V9.80424C17.689 10.0613 17.6564 10.3236 17.6503 10.5871C17.4936 12.0349 16.9832 13.4219 16.164 14.6258C15.8392 15.1144 15.4506 15.5573 15.0084 15.9429V15.9429Z"
              fill="#2A6BB3"
            />
            <path
              d="M4.17787 10.9131L1.55505 12.9139C1.43526 13.0051 1.31434 13.0945 1.19398 13.1848C1.18547 13.1774 1.17327 13.172 1.16872 13.1626C0.762675 12.3215 0.487745 11.4232 0.35349 10.4989C0.343484 10.2534 0.309644 10.0095 0.252441 9.77054V9.59625C0.265016 9.58965 0.275154 9.57922 0.281383 9.56645C0.287612 9.55369 0.2896 9.53926 0.287066 9.52529C0.285552 9.28061 0.28404 9.03603 0.282527 8.79153C0.339264 7.60181 0.63597 6.43586 1.15481 5.36372C1.16608 5.34427 1.1789 5.32574 1.19314 5.30835C1.40149 5.46475 1.61097 5.62059 1.81761 5.77842C2.60673 6.37811 3.39566 6.97828 4.1844 7.57891C4.06297 8.00168 3.9804 8.43462 3.93772 8.87241C3.8924 9.5039 3.96301 10.1384 4.14608 10.7445C4.15975 10.8001 4.17036 10.8564 4.17787 10.9131V10.9131Z"
              fill="#FFA900"
            />
            <path
              d="M4.18462 7.57882C3.39569 6.97856 2.60677 6.37831 1.81784 5.77805C1.61035 5.62051 1.40086 5.4658 1.19336 5.30826C1.45654 4.72674 1.7965 4.18312 2.20417 3.69196C3.6222 1.87497 5.70349 0.695199 7.99084 0.411759C9.23646 0.232201 10.5062 0.322784 11.7137 0.677388C12.9212 1.03199 14.0382 1.64231 14.989 2.46687C15.0742 2.53641 15.0781 2.57305 14.9984 2.65225C14.19 3.45347 13.3848 4.25773 12.583 5.06501C12.4894 5.15954 12.4411 5.11356 12.3693 5.05906C11.6887 4.51885 10.8774 4.16824 10.0176 4.04283C9.18028 3.90306 8.32147 3.97118 7.5167 4.24124C6.71193 4.51129 5.98586 4.97502 5.40236 5.59156C4.88299 6.11834 4.48688 6.7537 4.24253 7.45193C4.22635 7.4965 4.20478 7.5371 4.18462 7.57882Z"
              fill="#E71D22"
            />
          </svg>
        </span>
        Sign In with Google
      </NavLink>

      <NavLink to="#" className="  d-block facebook-btn   ">
        <span className="icongoogle">
          <svg
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.08035 17.322H3.85952C3.85952 14.8994 3.86063 12.4769 3.86283 10.0545C3.86283 9.9181 3.83141 9.88098 3.69055 9.88207C2.67336 9.88835 1.65589 9.88534 0.638428 9.88534V6.69905C1.66168 6.69905 2.68466 6.69631 3.70791 6.70232C3.83637 6.70232 3.86338 6.66768 3.86256 6.54603C3.85686 5.8325 3.85686 5.11885 3.86256 4.40515C3.86245 3.91811 3.91794 3.43265 4.02795 2.95794C4.24848 2.03466 4.6843 1.2573 5.55346 0.779165C6.13916 0.473254 6.79331 0.318327 7.45552 0.328614C8.35555 0.317431 9.25586 0.328568 10.157 0.322021C10.2736 0.322021 10.3059 0.349292 10.3053 0.467122C10.3002 1.43504 10.3002 2.40319 10.3053 3.37146C10.3053 3.49229 10.2678 3.51546 10.1545 3.51437C9.62359 3.50891 9.09212 3.504 8.56119 3.51437C7.76619 3.53155 7.27965 3.90768 7.12776 4.60538C7.09451 4.76251 7.07843 4.92271 7.0798 5.08324C7.08172 5.56437 7.08586 6.04578 7.07704 6.52692C7.07455 6.66329 7.10709 6.70094 7.24905 6.70012C8.44542 6.69384 9.64206 6.69659 10.8384 6.69659V6.86267C10.7764 6.90249 10.7927 6.97179 10.7833 7.02634C10.6304 7.91916 10.4808 8.8125 10.3345 9.70641C10.3144 9.8286 10.2863 9.8897 10.1322 9.88807C9.17675 9.87798 8.22075 9.88808 7.26531 9.87908C7.10819 9.87771 7.07731 9.92325 7.07759 10.07C7.082 12.4881 7.08292 14.9054 7.08035 17.322Z"
              fill="white"
            />
          </svg>
        </span>
        Sign In with Facebook
      </NavLink>

      <p>
        Don’t have an account? <NavLink to="/signup" className="linkgreen">Sign Up</NavLink>
      </p>
    </div>
  );
};

export default SignIn;
