import React from "react";
import { Breadcrumb } from "react-bootstrap";
import Layout from "../../layouts/Layout";

import branddetail1 from "../../assets/images/branddetails/branddetail-1.png";
import branddetail2 from "../../assets/images/branddetails/branddetail-2.png";
import branddetail3 from "../../assets/images/branddetails/branddetail-3.png";
import branddetail4 from "../../assets/images/branddetails/branddetail-4.png";
import branddetail5 from "../../assets/images/branddetails/branddetail-5.png";
import branddetail6 from "../../assets/images/branddetails/branddetail-6.png";
import branddetail7 from "../../assets/images/branddetails/branddetail-7.png";
import branddetail8 from "../../assets/images/branddetails/branddetail-8.png";
import branddetail9 from "../../assets/images/branddetails/branddetail-9.png";
import branddetail10 from "../../assets/images/branddetails/branddetail-10.png";
import branddetail11 from "../../assets/images/branddetails/branddetail-11.png";
import branddetail12 from "../../assets/images/branddetails/branddetail-12.png";
import branddetail13 from "../../assets/images/branddetails/branddetail-13.png";
import branddetail14 from "../../assets/images/branddetails/branddetail-14.png";
import branddetail15 from "../../assets/images/branddetails/branddetail-15.png";
import branddetail16 from "../../assets/images/branddetails/branddetail-16.png";
import branddetail17 from "../../assets/images/branddetails/branddetail-17.png";
import branddetail18 from "../../assets/images/branddetails/branddetail-18.png";
import branddetail19 from "../../assets/images/branddetails/branddetail-19.png";
import branddetail20 from "../../assets/images/branddetails/branddetail-20.png";
import branddetail21 from "../../assets/images/branddetails/branddetail-21.png";
import branddetail22 from "../../assets/images/branddetails/branddetail-22.png";
import branddetail23 from "../../assets/images/branddetails/branddetail-23.png";
import branddetail24 from "../../assets/images/branddetails/branddetail-24.png";
import Breadcrumbs from "../../components/breadcrumbs";
import { NavLink } from "react-router-dom";
const BrandProduct = [
  {
    id: 1,
    src: branddetail1,
    title: "Nike Store",
  },
  {
    id: 2,
    src: branddetail2,
    title: "Gucci",
  },
  {
    id: 3,
    src: branddetail3,
    title: "Louis Vuitton",
  },
  {
    id: 4,
    src: branddetail4,
    title: "Hermes",
  },
  {
    id: 5,
    src: branddetail5,
    title: "Zalando",
  },
  {
    id: 6,
    src: branddetail6,
    title: "Adidas",
  },
  {
    id: 7,
    src: branddetail7,
    title: "Tiffany & Co.",
  },
  {
    id: 8,
    src: branddetail8,
    title: "Zara",
  },
  {
    id: 9,
    src: branddetail9,
    title: "H&M",
  },
  {
    id: 10,
    src: branddetail10,
    title: "Cartier",
  },
  {
    id: 11,
    src: branddetail11,
    title: "Lululemon",
  },
  {
    id: 12,
    src: branddetail12,
    title: "Moncler",
  },
  {
    id: 13,
    src: branddetail13,
    title: "Chanel",
  },
  {
    id: 14,
    src: branddetail14,
    title: "Rolex",
  },
  {
    id: 15,
    src: branddetail15,
    title: "Patek Philippe",
  },
  {
    id: 16,
    src: branddetail16,
    title: "Prada",
  },
  {
    id: 17,
    src: branddetail17,
    title: "Uniqlo",
  },
  {
    id: 18,
    src: branddetail18,
    title: "Swarovski",
  },
  {
    id: 19,
    src: branddetail19,
    title: "Burberry",
  },
  {
    id: 20,
    src: branddetail20,
    title: "Polo Ralph Lauren",
  },
  {
    id: 21,
    src: branddetail21,
    title: "Tom Ford",
  },
  {
    id: 22,
    src: branddetail22,
    title: "The North Face ",
  },
  {
    id: 23,
    src: branddetail23,
    title: "Levi’s",
  },
  {
    id: 24,
    src: branddetail24,
    title: "Victoria’s Secret",
  },
];

function BrandDetailPage({isShow}) {
  return (
    <Layout isShow={isShow}>
<Breadcrumbs pagename="Store Details" show={true}/>

      <div className="branddetail-container margin-bottom-150">
        <div className="mainpadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="branddetailbanner mgr-b30">
                  <img src={branddetail22} alt="" />
                  <h2>Nike Store</h2>
                </div>
              </div>
            </div>

            <div className="row">
              {BrandProduct.map((els, i) => (
                <div className="col-xs-12 col-md-6 col-lg-4 col-xl-2">
                  <NavLink to="/productdetailpage">
                  <div className="storeboxesarea mgr-b30">
                    <img src={els.src} alt="" />
                    <h3>{els.title}</h3>
                    <h5>$200</h5>
                  </div>
                  </NavLink>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BrandDetailPage;
