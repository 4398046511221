import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import SideMenu from "../components/SideMenu";
import { MasterCardSvg, VisaCardSvg, DeleteIcon } from "../constants/svg";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Layout from "../layouts/Layout";
import Breadcrumbs from "../components/breadcrumbs";
import DeleteAlert from "../components/Modals/DeleteAlert";
import { NavLink } from "react-router-dom";
const BrandCards = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Layout>
        <Breadcrumbs pagename="Wallet" />
        <div className="mainpadding">
          <section class="main-wallet-section margin-bottom-150">
            <Container fluid>
              <Row>
                <Col
                  xs={12}
                  xl={3}
                  xxl={2}
                  md={3}
                  sm={12}
                  className="sidebar-class-col"
                >
                  <SideMenu />
                </Col>

                <Col
                  xxl={9}
                  xl={9}
                  md={9}
                  className="credit-card-history-main-div"
                >
                  <Row className="h-100">
                    <Col
                      sm={6}
                      lg={6}
                      xl={5}
                      xxl={5}
                      className="wallet-credit-card-col"
                    >
                      <div className="wallet-section">
                        <h6>Wallet</h6>
                        <div className="available-credits">
                          <p>Available Credits</p>
                          <p>$125.00</p>
                        </div>
                      </div>

                      <div className="mycard-section">
                        <h6>My Cards</h6>

                        <div className="wallet-card">
                          <Row>
                            <Col md={3} className="my-auto">
                              <MasterCardSvg />
                            </Col>
                            <Col md={6}>
                              <h2>Master Card</h2>
                              <p>548*******6548546</p>
                            </Col>
                            <Col md={3} className="my-auto text-end cards-svgs">
                              <Link onClick={() => setModalShow(true)}>
                                <DeleteIcon />
                              </Link>
                            </Col>
                          </Row>
                        </div>

                        <div className="wallet-card">
                          <Row>
                            <Col md={3} className="my-auto">
                              <a href="">
                                <VisaCardSvg />
                              </a>
                            </Col>
                            <Col md={6}>
                              <h2>Master Card</h2>
                              <p>548*******6548546</p>
                            </Col>
                            <Col md={3} className="my-auto text-end cards-svgs">
                              <Link onClick={() => setModalShow(true)}>
                                <DeleteIcon />
                              </Link>
                            </Col>
                            <DeleteAlert
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                            />
                          </Row>
                        </div>

                        <div className="add-new-btn">
                          {/* <a to="#">
                            <FaPlus />
                            Add New
                          </a> */}
                          <NavLink to="#">
                            +<span>Add New</span>
                          </NavLink>
                        </div>
                      </div>
                    </Col>

                    <Col
                      sm={6}
                      lg={6}
                      xl={5}
                      xxl={5}
                      md={9}
                      className="wallet-credit-card-history"
                    >
                      <div className="transaction-history">
                        <h6>Transactions History</h6>

                        <div className="historycard">
                          <Row>
                            <Col md={8} xl={8} className="person-name">
                              <p className="date">16 March 2022 - 02:30 am</p>
                              <h2>Nike Air Max</h2>
                            </Col>
                            <Col
                              md={4}
                              xl={4}
                              className="my-auto text-end person-purchase"
                            >
                              <p className="price">$125.00</p>
                            </Col>
                          </Row>
                        </div>
                        <div className="historycard">
                          <Row>
                            <Col md={8} xl={8} className="person-name">
                              <p className="date">16 March 2022 - 02:30 am</p>
                              <h2>Nike Air Max</h2>
                            </Col>
                            <Col
                              md={4}
                              xl={4}
                              className="my-auto text-end person-purchase"
                            >
                              <p className="price">$125.00</p>
                            </Col>
                          </Row>
                        </div>
                        <div className="historycard">
                          <Row>
                            <Col md={8} xl={8} className="person-name">
                              <p className="date">16 March 2022 - 02:30 am</p>
                              <h2>Nike Air Max</h2>
                            </Col>
                            <Col
                              md={4}
                              xl={4}
                              className="my-auto text-end person-purchase"
                            >
                              <p className="price">$125.00</p>
                            </Col>
                          </Row>
                        </div>
                        <div className="historycard">
                          <Row>
                            <Col md={8} xl={8} className="person-name">
                              <p className="date">16 March 2022 - 02:30 am</p>
                              <h2>Nike Air Max</h2>
                            </Col>
                            <Col
                              md={4}
                              xl={4}
                              className="my-auto text-end person-purchase"
                            >
                              <p className="price">$125.00</p>
                            </Col>
                          </Row>
                        </div>
                        <div className="historycard">
                          <Row>
                            <Col md={8} xl={8} className="person-name">
                              <p className="date">16 March 2022 - 02:30 am</p>
                              <h2>Nike Air Max</h2>
                            </Col>
                            <Col
                              md={4}
                              xl={4}
                              className="my-auto text-end person-purchase"
                            >
                              <p className="price">$125.00</p>
                            </Col>
                          </Row>
                        </div>
                        <div className="historycard">
                          <Row>
                            <Col md={8} xl={8} className="person-name">
                              <p className="date">16 March 2022 - 02:30 am</p>
                              <h2>Nike Air Max</h2>
                            </Col>
                            <Col
                              md={4}
                              xl={4}
                              className="my-auto text-end person-purchase"
                            >
                              <p className="price">$125.00</p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default BrandCards;
